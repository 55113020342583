var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-container",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.isloading,
          expression: "isloading",
        },
      ],
      staticClass: "slider-dialog",
      class: _vm.activeClass,
    },
    [
      _c("div", { staticClass: "header-menu" }, [
        _c(
          "div",
          { staticClass: "pull-right" },
          [
            _c("font-icon", {
              staticClass: "lbd-btn__close",
              attrs: { href: "#icon-ic_off_tj1" },
              nativeOn: {
                click: function ($event) {
                  return _vm.handleClose.apply(null, arguments)
                },
              },
            }),
          ],
          1
        ),
      ]),
      _c("div", { staticClass: "header-title" }, [
        _c("span", [_vm._v(_vm._s(_vm.job.customerName))]),
        _c("span", [_vm._v(_vm._s(_vm.job.jobName))]),
        _c("span", [
          _vm._v(
            _vm._s(_vm.status === "all" ? "变更推荐状态" : "未处理") + "（"
          ),
          _c("span", { staticClass: "color-orange" }, [
            _vm._v(_vm._s(_vm.page.total)),
          ]),
          _vm._v("）"),
        ]),
      ]),
      _c(
        "div",
        { staticClass: "list" },
        [
          _vm._l(_vm.listData, function (item, index) {
            return _vm.listData.length > 0
              ? _c("candidate-card", {
                  key: index,
                  class:
                    item.status === 1024 ||
                    item.status === 32 ||
                    (_vm.status !== "all" && item.status === 1)
                      ? "remove"
                      : "",
                  attrs: { "enable-bubble": true, "card-data": item },
                  on: { "show-dialog": _vm.showRecommendDialog },
                })
              : _vm._e()
          }),
          _vm.listData.length === 0
            ? _c("div", { staticClass: "default" }, [
                _c("div", { staticClass: "empty-img" }),
                _c("span", [_vm._v("暂无数据")]),
              ])
            : _vm._e(),
        ],
        2
      ),
      _c(
        "div",
        { staticClass: "footer" },
        [
          _c(
            "el-pagination",
            {
              staticClass: "el-pagination-workTable",
              attrs: {
                "current-page": _vm.page.current,
                layout: "total, prev, pager, next, slot",
                total: _vm.page.total,
                "page-size": _vm.page.take,
              },
              on: { "current-change": _vm.handleCurrentChange },
            },
            [
              _c("span", { staticClass: "pagination-text" }, [
                _c(
                  "span",
                  [
                    _vm._v("前往"),
                    _c("el-input", {
                      nativeOn: {
                        keyup: function ($event) {
                          if (
                            !$event.type.indexOf("key") &&
                            _vm._k(
                              $event.keyCode,
                              "enter",
                              13,
                              $event.key,
                              "Enter"
                            )
                          )
                            return null
                          return _vm.handlePagerJump.apply(null, arguments)
                        },
                      },
                      model: {
                        value: _vm.pagerJump,
                        callback: function ($$v) {
                          _vm.pagerJump = $$v
                        },
                        expression: "pagerJump",
                      },
                    }),
                    _vm._v("页"),
                  ],
                  1
                ),
                _c("span", { on: { click: _vm.handlePagerJump } }, [
                  _vm._v("跳转"),
                ]),
              ]),
            ]
          ),
        ],
        1
      ),
      _c("recommend-dialog-list", {
        ref: "recommendDialog",
        on: { "update-list": _vm.updateList },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }