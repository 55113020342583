<template>
    <div class="batch-operation">
        <el-button
            v-if="isMyOrder"
            class="batch-operation-btn update-status"
            @click="batchOperate('batchUpdateStatus')"
            :disabled="selectList.length == 0"
        >
            修改职位状态
        </el-button>
        <el-button
            class="batch-operation-btn add-itap"
            @click="batchOperate('batchAddItap')"
            :disabled="selectList.length == 0"
        >
            打标签
        </el-button>
        <el-dropdown
            class="record-job-dropdown"
            placement="top-start"
            v-if="isMyOrder && canRecord"
            @command="handleBatchRecordJob"
            :style="isShowRecordGuide ? 'z-index: 4001;' : ''"
        >
            <el-button
                class="batch-operation-btn record-job"
                :disabled="selectList.length == 0"
                :style="isShowRecordGuide ? 'pointer-events: none;' : ''"
            >
                职播
            </el-button>
            <div class="multiple-record-guide" v-if="isMyOrder && canRecord && isShowRecordGuide">
                <span class="text-operate-btn" @click="hideMulRecordGuide">好的</span>
            </div>
            <el-dropdown-menu slot="dropdown">
                <el-dropdown-item command="video">视频讲解</el-dropdown-item>
                <el-dropdown-item command="audio">语音讲解</el-dropdown-item>
            </el-dropdown-menu>
        </el-dropdown>
        <el-button
            v-if="!isMyOrder || !(isMyOrder && (user.canPublicizeInternalJob || user.isAdministrator) && user.isMarketJobWhiteList)"
            class="batch-operation-btn add-share-job"
            @click="batchOperate('batchAddToShareCard')"
            :disabled="selectList.length == 0"
        >
            分享职位
        </el-button>
        <el-dropdown
            class="more-operation-dropdown"
            placement="top-start"
            v-if="isMyOrder"
        >
            <el-button
                class="batch-operation-btn more-operation"
                :disabled="selectList.length == 0"
                icon="el-icon-menu"
            >
                更多操作
            </el-button>
            <el-dropdown-menu slot="dropdown" class="more-operation-menu">
                <el-dropdown-item
                    @click.native="batchOperate('batchTransferJob')"
                    :disabled="selectList.length == 0"
                >
                    移交职位
                </el-dropdown-item>
                <el-dropdown-item
                    v-if="(user.canPublicizeInternalJob || user.isAdministrator) && user.isMarketJobWhiteList"
                    @click.native="batchOperate('batchJobPublic')"
                    :disabled="cannotPublic"
                >
                    发布到市场
                </el-dropdown-item>
                <el-dropdown-item
                    v-if="(user.canPublicizeInternalJob || user.isAdministrator) && user.isMarketJobWhiteList"
                    @click.native="batchOperate('batchEditPublicFirm')"
                    :disabled="cannotEditPublicFirm"
                >
                    修改市场可见猎企
                </el-dropdown-item>
                <el-dropdown-item 
                    @click.native="batchOperate('batchAddToShareCard')"
                    v-if="(user.canPublicizeInternalJob || user.isAdministrator) && user.isMarketJobWhiteList"
                >
                    添加到职位卡片
                </el-dropdown-item>
            </el-dropdown-menu>
        </el-dropdown>
        <div class="job-record-guide-modal" v-if="isShowRecordGuide"></div>
    </div>
</template>

<script>
import emitter from "@src/js/mixins/emitter";
import moment from 'moment'

export default {
    name: "order-batch-operation",

    mixins: [emitter],

    props: {
        selectList: {
            type: Array
        },
        tableType: {
            type: [Number, String],
            default: 2
        },
        canRecord: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            loading: false,
            isShowRecordGuide: false
        };
    },
    mounted() {},
    computed: {
        user() {
            return this.$store.state.user.userInfo;
        },
        resumeIds() {
            let ids = [];
            this.selectList.forEach(item => {
                ids.push(item.resumeListItem.resumeId);
            });
            return ids;
        },
        cannotPublic() {
            return this.selectList.some(item => {
                return item.isPublic === true || item.jobType == 2;
            });
        },
        cannotEditPublicFirm() {
            return this.selectList.some(item => {
                return item.isPublic === false;
            });
        },
        isMyOrder() {
            return this.tableType == 1;
        }
    },
    methods: {
        batchOperate(command) {
            if((command == 'batchJobPublic' && this.cannotPublic) ||(command == 'batchEditPublicFirm' && this.cannotEditPublicFirm)) {
                return;
            }
            let dataJson = {};
            dataJson.command = command;
            dataJson.data = this.selectList;
            this.dispatch("MyOrdersWrapper", "job-operation", dataJson);
        },
        handleBatchRecordJob(command) {
            if(command == 'video') {
                this.batchOperate('batchRecordJobVideo');
            }
            if(command == 'audio') {
                this.batchOperate('batchRecordJobAudio');
            }
        },
        hideMulRecordGuide() {
            this.isShowRecordGuide = false;
            document.querySelector(".work-table-footer").style.overflow = '';
        }
    }
};
</script>

<style lang="scss" scoped>
.batch-operation {
    position: relative;
    .update-status {
        width: 104px;
        background-color: $primary;
    }
    .transfer-job {
        background-color: #fc7859;
    }
    .record-job {
        margin-left: 10px;
        background-color: #fc7859;
    }
    .add-share-job {
        margin-left: 10px;
        background-color: #EE9F1B;
    }
    .more-operation {
        background-color: #EE9F1B;
        padding: 0 10px;
        &:hover,
        &:focus {
            background-color: #FFB539;
        }
    }
    .more-operation-dropdown.el-dropdown {
        margin-left: 10px;
        .more-operation {
            span {
                margin-left: 0;
            }
        }
    }
    .multiple-record-guide {
        width: 346px;
        height: 132px;
        background: url("~@src/assets/images/workbench/multiple_record_guide.png") no-repeat center center;
        position: absolute;
        z-index: 4001;
        bottom: 28px;
        left: -120px;
        .text-operate-btn {
            position: absolute;
            bottom: 68px;
            right: 18px;
        }
    }
}
</style>

<style lang="scss">
    .more-operation-menu.el-dropdown-menu {
        padding: 0;
        margin: 0;
        .popper__arrow {
            display: none;
        }
        .el-dropdown-menu__item {
            line-height: 28px;
            padding: 0 10px;
            &.is-disabled {
                cursor: not-allowed;
                pointer-events: auto;
            }
        }
    }
    .job-record-guide-modal {
        position: fixed;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        z-index: 4000;
        background-color: rgba(0,0,0,0.5);
    }
</style>