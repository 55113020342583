var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "my-orders-table" },
    [
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.tableDataLoading,
              expression: "tableDataLoading",
            },
          ],
          ref: "multipleTable",
          staticStyle: { width: "100%" },
          attrs: {
            border: "",
            height: "700",
            "tooltip-effect": "light",
            data: _vm.tableData,
          },
          on: { "selection-change": _vm.handleSelectionChange },
        },
        [
          _c("el-table-column", {
            attrs: {
              fixed: "",
              width: "42",
              type: "selection",
              selectable: _vm.selectable,
              align: "center",
              "header-align": "center",
            },
          }),
          _c("el-table-column", {
            attrs: {
              fixed: "",
              width: "110",
              align: "left",
              "header-align": "left",
              "class-name": "table-header-dropdown-wrapper",
              resizable: false,
            },
            scopedSlots: _vm._u([
              {
                key: "header",
                fn: function (scope) {
                  return [
                    _c(
                      "el-dropdown",
                      {
                        staticClass: "table-header-dropdown",
                        attrs: { trigger: "click", placement: "bottom" },
                        on: { command: _vm.handleSortRule },
                      },
                      [
                        _c("span", { staticClass: "el-dropdown-link" }, [
                          _vm._v(
                            "\n                        " +
                              _vm._s(
                                _vm.sortRule == 2
                                  ? "最近推荐时间"
                                  : _vm.tableType == 1
                                  ? "创建时间"
                                  : "抢单时间"
                              ) +
                              "\n                        "
                          ),
                          _c("i", {
                            staticClass: "el-icon-arrow-down el-icon--right",
                          }),
                        ]),
                        _c(
                          "el-dropdown-menu",
                          {
                            staticClass: "table-header-dropdown-menu",
                            attrs: { slot: "dropdown", "visible-arrow": false },
                            slot: "dropdown",
                          },
                          [
                            _c(
                              "el-dropdown-item",
                              {
                                class:
                                  _vm.sortRule == 1
                                    ? "el-dropdown-item-selected"
                                    : "",
                                attrs: { command: 1 },
                              },
                              [
                                _vm._v(
                                  "\n                            " +
                                    _vm._s(
                                      _vm.tableType == 1
                                        ? "创建时间"
                                        : "抢单时间"
                                    ) +
                                    "\n                            "
                                ),
                                _c("font-icon", {
                                  staticClass: "table-body-icon sort-icon",
                                  attrs: { href: "#icon-orders_ic_paixu" },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "el-dropdown-item",
                              {
                                class:
                                  _vm.sortRule == 2
                                    ? "el-dropdown-item-selected"
                                    : "",
                                attrs: { command: 2 },
                              },
                              [
                                _vm._v(
                                  "\n                            最近推荐时间\n                        "
                                ),
                              ]
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]
                },
              },
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c(
                      "el-tooltip",
                      {
                        attrs: {
                          effect: "light",
                          placement: "bottom",
                          "open-delay": _vm.popoverDelay,
                          offset: 110,
                          "visible-arrow": false,
                          "popper-class": "time-tooltip-popper",
                          disabled:
                            _vm.sortRule == 2 &&
                            scope.row.lastlyRecommendDate ==
                              "0001-01-01 00:00:00",
                        },
                      },
                      [
                        _c(
                          "div",
                          { attrs: { slot: "content" }, slot: "content" },
                          [
                            _vm._v(
                              "\n                        " +
                                _vm._s(
                                  _vm._f("formatTime")(scope.row.created)
                                ) +
                                "\n                    "
                            ),
                          ]
                        ),
                        _c(
                          "span",
                          {
                            class:
                              scope.row.created == "0001-01-01 00:00:00"
                                ? "time-tooltip-show"
                                : "",
                          },
                          [
                            _vm._v(
                              "\n                        " +
                                _vm._s(
                                  _vm._f("formatDate")(scope.row.created)
                                ) +
                                "\n                    "
                            ),
                          ]
                        ),
                      ]
                    ),
                  ]
                },
              },
            ]),
          }),
          _c(
            "el-table-column",
            {
              attrs: {
                fixed: "",
                width: "258",
                label: "职位名称",
                "header-align": "left",
                "class-name": "name-cell",
                resizable: false,
              },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      _c(
                        "div",
                        { staticClass: "name-wrapper" },
                        [
                          scope.row.activityTags.length > 0
                            ? _vm._l(
                                scope.row.activityTags,
                                function (activityTag) {
                                  return activityTag.id === "NewJob"
                                    ? _c("font-icon", {
                                        key: activityTag.id,
                                        staticClass: "new-job-tag",
                                        attrs: {
                                          href: "#icon-orders_label_new",
                                        },
                                      })
                                    : _vm._e()
                                }
                              )
                            : _vm._e(),
                          _c(
                            "el-tooltip",
                            {
                              attrs: {
                                effect: "light",
                                content: scope.row.jobName,
                                placement: "top",
                                "open-delay": _vm.popoverDelay,
                                disabled: _vm.tooltipDisabled,
                              },
                              nativeOn: {
                                mouseenter: function ($event) {
                                  return _vm.handleShowTooltip($event)
                                },
                              },
                            },
                            [
                              _c(
                                "span",
                                {
                                  staticClass: "job-detail-link",
                                  on: {
                                    click: function ($event) {
                                      $event.preventDefault()
                                      return _vm.handleJumpDetail(
                                        scope.row,
                                        "job",
                                        scope.$index
                                      )
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n                            " +
                                      _vm._s(scope.row.jobName) +
                                      "\n                        "
                                  ),
                                ]
                              ),
                            ]
                          ),
                          scope.row.difficultDegree == 1
                            ? _c("font-icon", {
                                staticClass: "name-wrapper-icon tip-margin",
                                attrs: { href: "#icon-orders_lable_hard" },
                              })
                            : _vm._e(),
                          scope.row.emergencyDegree == 1
                            ? _c("font-icon", {
                                staticClass: "name-wrapper-icon tip-margin",
                                attrs: { href: "#icon-orders_lable_urgent" },
                              })
                            : _vm._e(),
                          scope.row.expectedRank
                            ? _c(
                                "el-tooltip",
                                {
                                  attrs: {
                                    effect: "light",
                                    content: scope.row.expectedRank,
                                    placement: "top",
                                    "open-delay": _vm.popoverDelay,
                                    disabled: _vm.tooltipDisabled,
                                  },
                                  nativeOn: {
                                    mouseenter: function ($event) {
                                      return _vm.handleShowTooltip($event)
                                    },
                                  },
                                },
                                [
                                  _c("span", { staticClass: "expected-rank" }, [
                                    _vm._v(_vm._s(scope.row.expectedRank)),
                                  ]),
                                ]
                              )
                            : _vm._e(),
                          scope.row.activityTags.length > 0
                            ? _vm._l(
                                scope.row.activityTags,
                                function (activityTag) {
                                  return activityTag.id !== "JobEmergency" &&
                                    activityTag.id !== "JobDifficultDegree" &&
                                    activityTag.id !== "NewJob" &&
                                    activityTag.id !== "StandstillJob"
                                    ? _c(
                                        "span",
                                        {
                                          key: activityTag.id,
                                          staticClass: "activity-tag",
                                        },
                                        [
                                          _vm._v(
                                            "\n                            " +
                                              _vm._s(activityTag.name) +
                                              "\n                        "
                                          ),
                                        ]
                                      )
                                    : _vm._e()
                                }
                              )
                            : _vm._e(),
                          scope.row.jobType == 3
                            ? _c("span", { staticClass: "activity-tag" }, [
                                _vm._v("HR"),
                              ])
                            : _vm._e(),
                          scope.row.jobType == 5
                            ? _c("span", { staticClass: "activity-tag" }, [
                                _vm._v("代运营"),
                              ])
                            : _vm._e(),
                          _c(
                            "el-dropdown",
                            {
                              directives: [
                                {
                                  name: "loading",
                                  rawName: "v-loading",
                                  value: _vm.jobOperationLoading,
                                  expression: "jobOperationLoading",
                                },
                              ],
                              ref: "jobOperation",
                              attrs: {
                                placement: "bottom-start",
                                trigger: "hover",
                                "hide-on-click": false,
                              },
                            },
                            [
                              _c(
                                "span",
                                { staticClass: "operation-icon" },
                                [
                                  _c("font-icon", {
                                    staticClass:
                                      "table-body-icon operation-icon-default tip-margin",
                                    attrs: { href: "#icon-ic_act_grey" },
                                  }),
                                  _c("font-icon", {
                                    staticClass:
                                      "table-body-icon operation-icon-hover tip-margin",
                                    attrs: { href: "#icon-ic_act_green" },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "el-dropdown-menu",
                                {
                                  staticClass: "operation-menu",
                                  attrs: { slot: "dropdown" },
                                  slot: "dropdown",
                                },
                                [
                                  (_vm.tableType == 1 &&
                                    (scope.row.jobStatus != 4 ||
                                      scope.row.jobStatus == 4 ||
                                      ((scope.row.orderCount == 0 ||
                                        (!scope.row.isPublic &&
                                          scope.row.jobType == 2)) &&
                                        scope.row.jobStatus != 4))) ||
                                  (scope.row.jobStatus != 4 &&
                                    scope.row.jobStatus != 2)
                                    ? _c(
                                        "div",
                                        [
                                          _vm.tableType == 1 &&
                                          scope.row.jobStatus != 4
                                            ? _c(
                                                "el-dropdown-item",
                                                {
                                                  nativeOn: {
                                                    click: function ($event) {
                                                      return _vm.jobOperation(
                                                        "changeJobStatus",
                                                        scope.row
                                                      )
                                                    },
                                                  },
                                                },
                                                [_vm._v("修改职位状态")]
                                              )
                                            : _vm._e(),
                                          _vm.tableType == 1 &&
                                          scope.row.jobStatus == 4
                                            ? _c(
                                                "el-dropdown-item",
                                                {
                                                  nativeOn: {
                                                    click: function ($event) {
                                                      return _vm.jobOperation(
                                                        "copyJob",
                                                        scope.row
                                                      )
                                                    },
                                                  },
                                                },
                                                [_vm._v("复制职位")]
                                              )
                                            : _vm._e(),
                                          _vm.tableType == 1 &&
                                          (scope.row.orderCount == 0 ||
                                            (!scope.row.isPublic &&
                                              scope.row.jobType == 2)) &&
                                          scope.row.jobStatus != 4
                                            ? _c(
                                                "el-dropdown-item",
                                                {
                                                  nativeOn: {
                                                    click: function ($event) {
                                                      return _vm.jobOperation(
                                                        "editJob",
                                                        scope.row
                                                      )
                                                    },
                                                  },
                                                },
                                                [_vm._v("编辑职位信息")]
                                              )
                                            : _vm._e(),
                                          _c(
                                            "el-dropdown-item",
                                            {
                                              nativeOn: {
                                                click: function ($event) {
                                                  return _vm.jobOperation(
                                                    "addItap",
                                                    scope.row
                                                  )
                                                },
                                              },
                                            },
                                            [_vm._v("打标签")]
                                          ),
                                          scope.row.jobStatus != 4 &&
                                          scope.row.jobStatus != 2
                                            ? _c(
                                                "el-dropdown-item",
                                                {
                                                  nativeOn: {
                                                    click: function ($event) {
                                                      return _vm.jobOperation(
                                                        "recommend",
                                                        scope.row
                                                      )
                                                    },
                                                  },
                                                },
                                                [_vm._v("推荐候选人")]
                                              )
                                            : _vm._e(),
                                          scope.row.jobStatus != 4 &&
                                          scope.row.jobStatus != 2
                                            ? _c(
                                                "el-dropdown-item",
                                                {
                                                  nativeOn: {
                                                    click: function ($event) {
                                                      return _vm.jobOperation(
                                                        "changeRecommendation",
                                                        scope.row
                                                      )
                                                    },
                                                  },
                                                },
                                                [_vm._v("变更推荐状态")]
                                              )
                                            : _vm._e(),
                                        ],
                                        1
                                      )
                                    : _c("div", [
                                        _c(
                                          "span",
                                          { staticClass: "no-dropdown-tip" },
                                          [
                                            _vm._v(
                                              "职位" +
                                                _vm._s(
                                                  scope.row.jobStatus == 4
                                                    ? "已完成"
                                                    : "已暂停"
                                                ) +
                                                "状态下无法操作"
                                            ),
                                          ]
                                        ),
                                      ]),
                                ]
                              ),
                            ],
                            1
                          ),
                        ],
                        2
                      ),
                    ]
                  },
                },
              ]),
            },
            [
              _c(
                "template",
                { slot: "header" },
                [
                  _c("select-popover", {
                    attrs: {
                      enableSearch: true,
                      enableCount: true,
                      selectItem: _vm.headerFilter.titleSearchGroupedItems,
                      label: "titles",
                      title: "职位名称",
                      maxSelect: 5,
                    },
                    on: { selectChange: _vm.selectDataChange },
                  }),
                ],
                1
              ),
            ],
            2
          ),
          _c(
            "el-table-column",
            {
              attrs: {
                label: "客户公司",
                prop: "customerName",
                "min-width": "98",
                "header-align": "left",
                "show-overflow-tooltip": true,
              },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      _c(
                        "el-tooltip",
                        {
                          attrs: {
                            effect: "light",
                            content: scope.row.customerName,
                            placement: "top",
                            "open-delay": _vm.popoverDelay,
                            disabled: _vm.tooltipDisabled,
                          },
                          nativeOn: {
                            mouseenter: function ($event) {
                              return _vm.handleShowTooltip($event)
                            },
                          },
                        },
                        [
                          _c(
                            "span",
                            {
                              staticClass: "customer-detail-link",
                              class: {
                                "no-link":
                                  scope.row.isFromAuthorization ||
                                  [4, 5].includes(scope.row.jobType),
                              },
                              on: {
                                click: function ($event) {
                                  $event.preventDefault()
                                  return _vm.handleJumpDetail(
                                    scope.row,
                                    "customer",
                                    scope.$index
                                  )
                                },
                              },
                            },
                            [
                              _vm._v(
                                "\n                            " +
                                  _vm._s(scope.row.customerName) +
                                  "\n                        "
                              ),
                            ]
                          ),
                        ]
                      ),
                    ]
                  },
                },
              ]),
            },
            [
              _c(
                "template",
                { slot: "header" },
                [
                  _c("select-popover", {
                    attrs: {
                      enableSearch: true,
                      enableCount: true,
                      selectItem:
                        _vm.headerFilter.customerNameSearchGroupedItems,
                      label: "customerNames",
                      title: "客户公司",
                      maxSelect: 5,
                    },
                    on: { selectChange: _vm.selectDataChange },
                  }),
                ],
                1
              ),
            ],
            2
          ),
          _c(
            "el-table-column",
            {
              attrs: { label: "职位状态", width: "98", "header-align": "left" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      _c("div", [
                        _vm._v(
                          _vm._s(_vm._f("statusFilter")(scope.row.jobStatus))
                        ),
                      ]),
                    ]
                  },
                },
              ]),
            },
            [
              _c(
                "template",
                { slot: "header" },
                [
                  _c("select-popover", {
                    attrs: {
                      enableSearch: false,
                      enableCount: true,
                      selectItem: _vm.headerFilter.statuSearchGroupedItems,
                      label: "status",
                      title: "职位状态",
                      maxSelect: 5,
                    },
                    on: { selectChange: _vm.selectDataChange },
                  }),
                ],
                1
              ),
            ],
            2
          ),
          _c(
            "el-table-column",
            {
              attrs: {
                label: "未处理",
                "min-width": "90",
                "header-align": "left",
              },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      scope.row.unprocessedCount > 0
                        ? _c("div", { staticClass: "dispose-wrapper" }, [
                            _c("span", [
                              _vm._v(_vm._s(scope.row.unprocessedCount)),
                            ]),
                            _vm.tableType == 1
                              ? _c(
                                  "span",
                                  {
                                    staticClass: "dispose-btn",
                                    on: {
                                      click: function ($event) {
                                        return _vm.jobOperation(
                                          "goProcess",
                                          scope.row
                                        )
                                      },
                                    },
                                  },
                                  [_vm._v("去处理")]
                                )
                              : _vm._e(),
                          ])
                        : _c("div", [_c("span", [_vm._v("0")])]),
                    ]
                  },
                },
              ]),
            },
            [
              _c("template", { slot: "header" }, [
                _c(
                  "span",
                  { staticClass: "table-header-tip-cell" },
                  [
                    _vm._v(
                      "\n                    未处理\n                    "
                    ),
                    _c(
                      "el-tooltip",
                      {
                        attrs: {
                          content:
                            _vm.tableType == 1
                              ? "当前职位存在未处理推荐，请及时处理"
                              : "当前推荐未被及时处理，请尽快与职位创建者沟通",
                          placement: "bottom-start",
                          effect: "light",
                          "visible-arrow": false,
                          "popper-class": "table-header-tooltip",
                        },
                      },
                      [
                        _c(
                          "span",
                          { staticClass: "icon-question" },
                          [
                            _c("font-icon", {
                              staticClass:
                                "table-header-tip-icon question-hover",
                              attrs: { href: "#icon-talent_ql" },
                            }),
                            _c("font-icon", {
                              staticClass:
                                "table-header-tip-icon question-no-hover",
                              attrs: { href: "#icon-talent_qd" },
                            }),
                          ],
                          1
                        ),
                      ]
                    ),
                  ],
                  1
                ),
              ]),
            ],
            2
          ),
          _c(
            "el-table-column",
            {
              attrs: {
                width: "258",
                "header-align": "left",
                "class-name": "recommend-cell",
              },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      _c("div", [
                        _vm._v(
                          "\n                    " +
                            _vm._s(scope.row.recommendCount) +
                            "推 ->\n                    " +
                            _vm._s(scope.row.acceptedCount) +
                            "受 ->\n                    " +
                            _vm._s(scope.row.interviewCount) +
                            "面 ->\n                    " +
                            _vm._s(scope.row.offerCount) +
                            " Offer\n                "
                        ),
                      ]),
                    ]
                  },
                },
              ]),
            },
            [
              _c("template", { slot: "header" }, [
                _c(
                  "span",
                  { staticClass: "table-header-tip-cell" },
                  [
                    _c("span", { staticStyle: { color: "#FF7700" } }, [
                      _vm._v("推荐总数>>"),
                    ]),
                    _c("span", { staticStyle: { color: "#F5A623" } }, [
                      _vm._v("接受>>"),
                    ]),
                    _c("span", { staticStyle: { color: "#38BC9D" } }, [
                      _vm._v("面试>>"),
                    ]),
                    _c("span", { staticStyle: { color: "#6FBBFB" } }, [
                      _vm._v("offer"),
                    ]),
                    _c(
                      "el-tooltip",
                      {
                        attrs: {
                          content:
                            _vm.tableType == 1
                              ? "当前职位在选择时间范围内的全部推荐情况"
                              : "在所选时间范围内，当前登录用户的单个职位的全部推荐情况",
                          placement: "bottom-start",
                          effect: "light",
                          "visible-arrow": false,
                          "popper-class": "table-header-tooltip",
                        },
                      },
                      [
                        _c(
                          "span",
                          { staticClass: "icon-question" },
                          [
                            _c("font-icon", {
                              staticClass:
                                "table-header-tip-icon question-hover",
                              attrs: { href: "#icon-talent_ql" },
                            }),
                            _c("font-icon", {
                              staticClass:
                                "table-header-tip-icon question-no-hover",
                              attrs: { href: "#icon-talent_qd" },
                            }),
                          ],
                          1
                        ),
                      ]
                    ),
                  ],
                  1
                ),
              ]),
            ],
            2
          ),
          _c("el-table-column", {
            attrs: {
              label: "抢单",
              align: "left",
              width: "56",
              "header-align": "left",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [_c("div", [_vm._v(_vm._s(scope.row.orderCount))])]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              align: "left",
              "min-width": "165",
              "header-align": "left",
              label: "职位基本要求",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c(
                      "el-tooltip",
                      {
                        attrs: {
                          effect: "light",
                          placement: "top",
                          "open-delay": _vm.popoverDelay,
                          disabled: _vm.tooltipDisabled,
                        },
                        nativeOn: {
                          mouseenter: function ($event) {
                            return _vm.handleShowTooltip($event)
                          },
                        },
                      },
                      [
                        _c(
                          "div",
                          { attrs: { slot: "content" }, slot: "content" },
                          [
                            scope.row.minYearOfExperience != undefined
                              ? _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      _vm._f("yearOfExperienceFilter")(
                                        scope.row.minYearOfExperience
                                      )
                                    ) + " | "
                                  ),
                                ])
                              : _vm._e(),
                            scope.row.degree != undefined
                              ? _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      _vm._f("degreeFilter")(scope.row.degree)
                                    ) + " | "
                                  ),
                                ])
                              : _vm._e(),
                            scope.row.recruitingCount
                              ? _c("span", [
                                  _vm._v(
                                    "需" +
                                      _vm._s(scope.row.recruitingCount) +
                                      "人"
                                  ),
                                ])
                              : _vm._e(),
                          ]
                        ),
                        _c(
                          "span",
                          { staticClass: "text-ellipsis require-list" },
                          [
                            _c(
                              "span",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value:
                                      scope.row.minYearOfExperience !=
                                      undefined,
                                    expression:
                                      "scope.row.minYearOfExperience != undefined",
                                  },
                                ],
                              },
                              [
                                _vm._v(
                                  _vm._s(
                                    _vm._f("yearOfExperienceFilter")(
                                      scope.row.minYearOfExperience
                                    )
                                  ) + " | "
                                ),
                              ]
                            ),
                            _c(
                              "span",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: scope.row.degree != undefined,
                                    expression: "scope.row.degree != undefined",
                                  },
                                ],
                              },
                              [
                                _vm._v(
                                  _vm._s(
                                    _vm._f("degreeFilter")(scope.row.degree)
                                  ) + " | "
                                ),
                              ]
                            ),
                            _c(
                              "span",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: scope.row.recruitingCount != 0,
                                    expression:
                                      "scope.row.recruitingCount != 0",
                                  },
                                ],
                              },
                              [
                                _vm._v(
                                  "需" +
                                    _vm._s(scope.row.recruitingCount) +
                                    "人"
                                ),
                              ]
                            ),
                          ]
                        ),
                      ]
                    ),
                  ]
                },
              },
            ]),
          }),
          _vm.tableType == 2
            ? _c("el-table-column", {
                attrs: {
                  label: "佣金情况",
                  "min-width": "120",
                  "header-align": "left",
                  "class-name": "table-header-dropdown-wrapper",
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "header",
                      fn: function (scope) {
                        return [
                          _vm.tableData.length > 0
                            ? _c(
                                "el-dropdown",
                                {
                                  staticClass: "table-header-dropdown",
                                  attrs: {
                                    trigger: "click",
                                    placement: "bottom-start",
                                  },
                                  on: {
                                    command: _vm.handleCommissionValueType,
                                  },
                                },
                                [
                                  _c(
                                    "span",
                                    { staticClass: "el-dropdown-link" },
                                    [
                                      _vm._v(
                                        "\n                        佣金情况\n                        "
                                      ),
                                      _c("i", {
                                        staticClass:
                                          "el-icon-arrow-down el-icon--right",
                                      }),
                                    ]
                                  ),
                                  _c(
                                    "el-dropdown-menu",
                                    {
                                      staticClass: "table-header-dropdown-menu",
                                      attrs: {
                                        slot: "dropdown",
                                        "visible-arrow": false,
                                      },
                                      slot: "dropdown",
                                    },
                                    [
                                      _c(
                                        "el-dropdown-item",
                                        {
                                          class:
                                            _vm.commissionValueType == 0
                                              ? "el-dropdown-item-selected"
                                              : "",
                                          attrs: { command: 0 },
                                        },
                                        [
                                          _vm._v(
                                            "\n                            全部\n                        "
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "el-dropdown-item",
                                        {
                                          class:
                                            _vm.commissionValueType == 2
                                              ? "el-dropdown-item-selected"
                                              : "",
                                          attrs: { command: 2 },
                                        },
                                        [
                                          _vm._v(
                                            "\n                            固定佣金\n                        "
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "el-dropdown-item",
                                        {
                                          class:
                                            _vm.commissionValueType == 1
                                              ? "el-dropdown-item-selected"
                                              : "",
                                          attrs: { command: 1 },
                                        },
                                        [
                                          _vm._v(
                                            "\n                            按比例佣金\n                        "
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            : _c("span", [_vm._v("佣金情况")]),
                        ]
                      },
                    },
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          scope.row.commissionType == 0 &&
                          scope.row.minCommission >= 100000
                            ? _c(
                                "el-tooltip",
                                {
                                  attrs: {
                                    effect: "light",
                                    placement: "top",
                                    "open-delay": _vm.popoverDelay,
                                  },
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      attrs: { slot: "content" },
                                      slot: "content",
                                    },
                                    [
                                      _vm._v(
                                        "\n                        " +
                                          _vm._s(
                                            _vm._f("commissionFilter")(
                                              scope.row.minCommission
                                            )
                                          ) +
                                          "k-" +
                                          _vm._s(
                                            _vm._f("commissionFilter")(
                                              scope.row.maxCommission
                                            )
                                          ) +
                                          "k(" +
                                          _vm._s(
                                            scope.row.commissionValue > 100
                                              ? 0
                                              : scope.row.commissionValue
                                          ) +
                                          "%)\n                    "
                                      ),
                                    ]
                                  ),
                                  _c("div", { staticClass: "text-ellipsis" }, [
                                    _vm._v(
                                      "\n                        " +
                                        _vm._s(
                                          _vm._f("commissionFilter")(
                                            scope.row.minCommission
                                          )
                                        ) +
                                        "k-" +
                                        _vm._s(
                                          _vm._f("commissionFilter")(
                                            scope.row.maxCommission
                                          )
                                        ) +
                                        "k(" +
                                        _vm._s(
                                          scope.row.commissionValue > 100
                                            ? 0
                                            : scope.row.commissionValue
                                        ) +
                                        "%)\n                    "
                                    ),
                                  ]),
                                ]
                              )
                            : _c("div", [
                                scope.row.commissionType == 1
                                  ? _c("span", [
                                      _vm._v(
                                        "\n                        " +
                                          _vm._s(
                                            _vm._f("commissionFilter")(
                                              scope.row.commissionValue
                                            )
                                          ) +
                                          "k\n                    "
                                      ),
                                    ])
                                  : _c("span", [
                                      _vm._v(
                                        "\n                        " +
                                          _vm._s(
                                            _vm._f("commissionFilter")(
                                              scope.row.minCommission
                                            )
                                          ) +
                                          "k-" +
                                          _vm._s(
                                            _vm._f("commissionFilter")(
                                              scope.row.maxCommission
                                            )
                                          ) +
                                          "k(" +
                                          _vm._s(
                                            scope.row.commissionValue > 100
                                              ? 0
                                              : scope.row.commissionValue
                                          ) +
                                          "%)\n                    "
                                      ),
                                    ]),
                              ]),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  1964316007
                ),
              })
            : _vm._e(),
          _c(
            "el-table-column",
            {
              attrs: {
                label: "标签",
                "min-width": "100",
                "header-align": "left",
              },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      scope.row.tags.length > 0
                        ? _c("add-itap-popover", {
                            attrs: { tagData: scope.row, isResume: false },
                          })
                        : _c(
                            "span",
                            {
                              staticClass: "cursor-pointer",
                              on: {
                                click: function ($event) {
                                  return _vm.jobOperation("addItap", scope.row)
                                },
                              },
                            },
                            [
                              _c("font-icon", {
                                staticClass: "table-body-icon-big icon-inquiry",
                                attrs: { href: "#icon-talent_lable" },
                              }),
                              _c("span", { staticClass: "color-warn" }, [
                                _vm._v(" 添加"),
                              ]),
                            ],
                            1
                          ),
                    ]
                  },
                },
              ]),
            },
            [
              _c(
                "template",
                { slot: "header" },
                [
                  _c("select-popover", {
                    attrs: {
                      enableSearch: true,
                      enableCount: true,
                      selectItem: _vm.headerFilter.tagSearchGroupedItems,
                      label: "tags",
                      title: "标签",
                      maxSelect: 5,
                    },
                    on: { selectChange: _vm.selectDataChange },
                  }),
                ],
                1
              ),
            ],
            2
          ),
          _c("template", { slot: "empty" }, [
            _c("div", [
              _c("span", { staticClass: "empty-img" }),
              _c("p", { staticClass: "empty-data" }, [_vm._v("暂无数据")]),
            ]),
          ]),
        ],
        2
      ),
      _c("table-page-tab", {
        attrs: { filterIndex: _vm.tableType - 1, pageTabs: _vm.pageTabJson },
      }),
      _c(
        "div",
        { staticClass: "work-table-footer" },
        [
          _c(
            "div",
            { staticClass: "footer-left" },
            [
              _c("div", { staticClass: "footer-select" }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.allSelect,
                      expression: "allSelect",
                    },
                  ],
                  attrs: { hidden: "", type: "checkbox" },
                  domProps: {
                    checked: Array.isArray(_vm.allSelect)
                      ? _vm._i(_vm.allSelect, null) > -1
                      : _vm.allSelect,
                  },
                  on: {
                    change: function ($event) {
                      var $$a = _vm.allSelect,
                        $$el = $event.target,
                        $$c = $$el.checked ? true : false
                      if (Array.isArray($$a)) {
                        var $$v = null,
                          $$i = _vm._i($$a, $$v)
                        if ($$el.checked) {
                          $$i < 0 && (_vm.allSelect = $$a.concat([$$v]))
                        } else {
                          $$i > -1 &&
                            (_vm.allSelect = $$a
                              .slice(0, $$i)
                              .concat($$a.slice($$i + 1)))
                        }
                      } else {
                        _vm.allSelect = $$c
                      }
                    },
                  },
                }),
                _c("span", {
                  staticClass: "all-select-checkbox",
                  on: { click: _vm.handleAllSelect },
                }),
                _c("span", [_vm._v("全选")]),
                _c("span", { staticClass: "select-count" }, [
                  _vm._v("已选择 "),
                  _c("span", { staticClass: "color-orange" }, [
                    _vm._v(_vm._s(_vm.multipleSelection.length)),
                  ]),
                  _vm._v(" 个职位"),
                ]),
              ]),
              _c("batch-operation", {
                ref: "batchOperation",
                attrs: {
                  selectList: _vm.multipleSelection,
                  tableType: _vm.tableType,
                  canRecord: _vm.canRecord,
                },
              }),
            ],
            1
          ),
          _c(
            "el-pagination",
            {
              staticClass: "el-pagination-workTable",
              attrs: {
                "current-page": _vm.page.current,
                "page-sizes": [20, 30, 50],
                "page-size": _vm.page.size,
                layout: "total, sizes, prev, pager, next, slot",
                total: _vm.page.total,
              },
              on: {
                "size-change": _vm.handleSizeChange,
                "current-change": _vm.handleCurrentChange,
              },
            },
            [
              _c("span", { staticClass: "pagination-text" }, [
                _c(
                  "span",
                  [
                    _vm._v("前往"),
                    _c("el-input", {
                      nativeOn: {
                        keyup: function ($event) {
                          if (
                            !$event.type.indexOf("key") &&
                            _vm._k(
                              $event.keyCode,
                              "enter",
                              13,
                              $event.key,
                              "Enter"
                            )
                          )
                            return null
                          return _vm.handlePagerJump.apply(null, arguments)
                        },
                      },
                      model: {
                        value: _vm.pagerJump,
                        callback: function ($$v) {
                          _vm.pagerJump = $$v
                        },
                        expression: "pagerJump",
                      },
                    }),
                    _vm._v("页"),
                  ],
                  1
                ),
                _c("span", { on: { click: _vm.handlePagerJump } }, [
                  _vm._v("跳转"),
                ]),
              ]),
            ]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }