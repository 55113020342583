import { tag as tagUrl } from '#/js/config/javaApi.json';

export default {
    getTagList() {
        return _request({
            method: 'POST',
            url: tagUrl.tag_list,
            baseURL: 'LbdJavaApi',
            javaProject: 'oldJavaApi',
            headers: {
                "AuthorizationTag": _getCookie('access_token')
            }
        })
    },
    addTag(params = { categoryId = "", tagName = "" } = {}) {
        params.sourceChannel = "LBD";
        return _request({
            method: 'POST',
            url: tagUrl.tag_add,
            baseURL: 'LbdJavaApi',
            javaProject: 'oldJavaApi',
            headers: {
                "AuthorizationTag": _getCookie('access_token')
            },
            data: params
        })
    },
    addTagCategory(params = { categoryName = "" } = {}) {
        params.sourceChannel = "LBD";
        return _request({
            method: 'POST',
            url: tagUrl.tag_add_category,
            baseURL: 'LbdJavaApi',
            javaProject: 'oldJavaApi',
            headers: {
                "AuthorizationTag": _getCookie('access_token')
            },
            data: params
        })
    },
    batchDeleteTag(params = { tagIds = [] } = {}) {
        return _request({
            method: 'DELETE',
            url: tagUrl.tag_batch_delete,
            baseURL: 'LbdJavaApi',
            javaProject: 'oldJavaApi',
            headers: {
                "AuthorizationTag": _getCookie('access_token')
            },
            data: params
        })
    },
    batchMoveTag(params = { categoryId = "", tagIds = [] } = {}) {
        return _request({
            method: 'POST',
            url: tagUrl.tag_batch_move,
            baseURL: 'LbdJavaApi',
            javaProject: 'oldJavaApi',
            headers: {
                "AuthorizationTag": _getCookie('access_token')
            },
            data: params
        })
    },
    deleteCategory(params = { categoryId = "" } = {}) {
        return _request({
            method: 'DELETE',
            url: tagUrl.tag_delete_category,
            baseURL: 'LbdJavaApi',
            javaProject: 'oldJavaApi',
            headers: {
                "AuthorizationTag": _getCookie('access_token')
            },
            data: params
        })
    },
    editTag(params = { tagId = "", tagName = "" } = {}) {
        return _request({
            method: 'POST',
            url: tagUrl.tag_edit,
            baseURL: 'LbdJavaApi',
            javaProject: 'oldJavaApi',
            headers: {
                "AuthorizationTag": _getCookie('access_token')
            },
            data: params,
            throwBusinessError: true
        })
    },
    mergeTag(params = { fromTagId = "", toTagId = "" } = {}) {
        return _request({
            method: 'POST',
            url: tagUrl.tag_merge,
            baseURL: 'LbdJavaApi',
            javaProject: 'oldJavaApi',
            headers: {
                "AuthorizationTag": _getCookie('access_token')
            },
            data: params
        })
    },
    editTagCategory(params = { categoryId = "", tagCategoryName = "" } = {}) {
        return _request({
            method: 'POST',
            url: tagUrl.tag_edit_category,
            baseURL: 'LbdJavaApi',
            javaProject: 'oldJavaApi',
            headers: {
                "AuthorizationTag": _getCookie('access_token')
            },
            data: params
        })
    },
    getResumeTagList(params) {
        return _request({
            method: 'POST',
            url: tagUrl.tag_resume_list,
            data: params,
            baseURL: 'LbdJavaApi',
            javaProject: 'oldJavaApi',
            headers: {
                "AuthorizationTag": _getCookie('access_token')
            }
        })
    },
}
