var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "candidate-card" }, [
    _c(
      "div",
      { staticClass: "top-area" },
      [
        _c(
          "div",
          { staticClass: "pull-right" },
          [
            _c("operation", {
              attrs: { recommend: _vm.cardData },
              on: { "show-dialog": _vm.showDialog },
            }),
          ],
          1
        ),
        _c("span", { staticClass: "name" }, [
          _c(
            "a",
            {
              attrs: {
                href: `/#/candidateDetail/${_vm.cardData.candidateId}/recommendation/${_vm.cardData.id}`,
              },
            },
            [
              _vm._v(
                "\n                " +
                  _vm._s(_vm.cardData.candidateName) +
                  "\n            "
              ),
            ]
          ),
        ]),
        _vm.enableBubble
          ? _c(
              "bubble",
              {
                attrs: {
                  "colorful-font": true,
                  size: "small",
                  type: _vm.colorType,
                  enableCount: false,
                  active: false,
                },
              },
              [_vm._v("\n            " + _vm._s(_vm.status) + "\n        ")]
            )
          : _vm._e(),
      ],
      1
    ),
    _c("div", { staticClass: "info-area" }, [
      _c("div", { staticClass: "flex" }, [
        _c("div", { staticClass: "flex-cell" }, [
          _c("span", { staticClass: "color-gray" }, [_vm._v("当前职位：")]),
          _c("span", { attrs: { title: _vm.cardData.title } }, [
            _vm._v(_vm._s(_vm.cardData.title)),
          ]),
        ]),
        _c("div", { staticClass: "flex-cell" }, [
          _c("span", { staticClass: "color-gray" }, [_vm._v("当前公司：")]),
          _c("span", { attrs: { title: _vm.cardData.company } }, [
            _vm._v(
              "\n                    " +
                _vm._s(_vm.cardData.company) +
                "\n                "
            ),
          ]),
        ]),
      ]),
      _c("div", { staticClass: "flex" }, [
        _c("div", { staticClass: "flex-cell" }, [
          _c("span", { staticClass: "color-gray" }, [_vm._v("推荐人：")]),
          _c("span", { attrs: { title: _vm.cardData.owner } }, [
            _vm._v(_vm._s(_vm.cardData.owner)),
          ]),
        ]),
        _c("div", { staticClass: "flex-cell" }, [
          _c("span", { staticClass: "color-gray" }, [_vm._v("推荐时间：")]),
          _c("span", { attrs: { title: _vm.cardData.created } }, [
            _vm._v(
              "\n                    " +
                _vm._s(_vm.cardData.created) +
                "\n                "
            ),
          ]),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }