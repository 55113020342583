<template>
    <div class="candidate-card">
        <div class="top-area">
            <div class="pull-right">
                <operation :recommend="cardData" @show-dialog="showDialog"></operation>
            </div>
            <span class="name">
                <!-- <a :href="`/Headhunting/MyCompany.html#/candidateDetail/${cardData.candidateId}/recommendation/${cardData.id}`"> -->
                <a :href="`/#/candidateDetail/${cardData.candidateId}/recommendation/${cardData.id}`">
                    {{ cardData.candidateName }}
                </a>
            </span>
            <bubble
                v-if="enableBubble"
                :colorful-font="true"
                size="small"
                :type="colorType"
                :enableCount="false"
                :active="false"
                >
                {{ status }}
            </bubble>
        </div>
        <div class="info-area">
            <div class="flex">
                <div class="flex-cell">
                    <span class="color-gray">当前职位：</span>
                    <span :title="cardData.title">{{ cardData.title }}</span>
                </div>
                <div class="flex-cell">
                    <span class="color-gray">当前公司：</span>
                    <span :title="cardData.company">
                        {{ cardData.company }}
                    </span>
                </div>
            </div>

            <div class="flex">
                <div class="flex-cell">
                    <span class="color-gray">推荐人：</span>
                    <span :title="cardData.owner">{{ cardData.owner }}</span>
                </div>
                <div class="flex-cell">
                    <span class="color-gray">推荐时间：</span>
                    <span :title="cardData.created">
                        {{ cardData.created }}
                    </span>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
    import Bubble from "../bubble";
    import Operation from '#/component/workTable/recommend-operation';
    import { recommendDetail as OPERATION_STATUS_CONFIG } from '#/js/config/recommend-detail.json';
    export default {
        name: "candidate-card",
        components: {
            Bubble,
            Operation
        },
        props: {
            enableBubble : {
                default: false,
                type: Boolean
            },
            cardData: {
                default: {},
                type: Object
            }
        },
        computed: {
            status (){
                return OPERATION_STATUS_CONFIG.find(item => {
                    if(item.status === this.cardData.status){
                        return item.text;
                    }
                }).text;
            },
            colorType(){
                switch (this.cardData.status){
                    case 0:case 4:case 2:
                        return 'orange-dark';
                    case 1:
                        return 'blue';
                    default:
                        return 'primary';
                }
            }
        },
        mounted(){

        },
        methods: {
            showDialog (...params){
                this.$emit('show-dialog', ...params);
            }
        }
    }
</script>

<style lang="scss" scoped>
    .candidate-card{

        margin: 0 10px;
        padding: 16px 0;
        border-bottom: 1px solid #ddd;

        .color-gray{
            color: rgba(#666, .8);
        }

        .name{
            font-weight: bold;
        }

        .pull-right{
            position: absolute;
            right: 0;
        }

        .flex {
            display: flex;
            justify-content: start;
            margin-bottom: 8px;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;

            &:first-child{
                margin-top:  8px;
            }

            &:last-child{
                margin-bottom: 0;
            }

            .flex-cell{
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
                line-height: 19px;
                width: 50%;
            }
        }
    }
</style>
