<template>
    <el-dialog
        width="480px"
        title="修改市场可见猎企"
        :visible.sync="dialogVisible"
        v-loading="loading"
        @close="handleCloseDialog"
        :close-on-click-modal="false"
        :close-on-press-escape="false"
    >
        <span class="el-form-item__label">设置可见猎企:</span>
        <el-select
            multiple
            class="input-visible-firm"
            placeholder="请选择可见猎企"
            v-model="visibleFirmIdList"
        >
            <el-option
                label="全部猎企(默认)"
                :value="0"
                @click.native.prevent="firmSelectedAll"
            ></el-option>
            <el-option
                v-for="(item, index) in companyList"
                :label="item.name"
                :key="index"
                :value="item.firmId"
                class="company-item"
                @click.native="firmSelected(index)"
            ></el-option>
            <el-option
                label="加载更多"
                class="get-more-btn"
                @click.native.stop="getCompanyList"
                v-if="hasMoreCompany"
            ></el-option>
            <span slot="empty">暂无数据</span>
        </el-select>
        <span slot="footer" class="dialog-footer">
            <el-button @click="handleCloseDialog">取消</el-button>
            <el-button type="primary" @click="editVisibleFirm">确定</el-button>
        </span>
    </el-dialog>
</template>

<script>
import { job as jobUrl, company as companyUrl } from '#/js/config/api.json';
export default {
    data() {
        return {
            loading: false,
            jobData: {
                name: "",
                location: "",
                firmShortName: "",
                minSalar: 0,
                commissionType: 0,
                commissionValue: 0,
                guaranteePeriod: 0
            },
            dialogVisible: false,
            visibleFirmIdList:  [0],
            companyList: [],
            fetchCompanyStart: 0,
            hasMoreCompany: false,
            jobList: []
        }
    },
    methods: {
        show(jobData) {
            this.jobList = jobData;
            this.getCompanyList();
            this.dialogVisible = true;
        },
        getCompanyList() {
            _request({
                url: companyUrl.white_list_firms,
                method: "GET",
                data: {
                    start: this.fetchCompanyStart,
                    take: 20
                }
            }).then((res) => {
                if(res.length == 20) {
                    this.hasMoreCompany = true;
                    this.fetchCompanyStart += 20;
                } else {
                    this.hasMoreCompany = false;
                }
                if(res.length > 0) {
                    res.forEach((item) => {
                        item.selected = false;
                    })
                }
                this.companyList = this.companyList.concat(res);
            })
        },
        firmSelectedAll() {
            this.visibleFirmIdList = [0];
            this.companyList.forEach((item) => {
                item.selected = false;
            });
        },
        firmSelected(index) {
            let visibleFirmIdList = this.visibleFirmIdList;
            if(visibleFirmIdList[0] == 0) {
                this.visibleFirmIdList.shift();
            }
            if(visibleFirmIdList[0] !== 0 && visibleFirmIdList.length == this.companyList.length) {
                this.visibleFirmIdList = [0];
            }
            let selected = this.companyList[index].selected;
            this.companyList[index].selected = !selected;
        },
        editVisibleFirm() {
            let ids = [],
                params = {
                    jobIds: [],
                    visibleFirmIds: []
                };
            this.loading = true;
            this.jobList.forEach(item => {
                ids.push(item.jobId);
            });
            params.jobIds = ids;
            if(this.visibleFirmIdList[0] == 0) {
                params.visibleFirmIds = ["00000000-0000-0000-0000-000000000000"];
            } else {
                params.visibleFirmIds = this.visibleFirmIdList;
            }
            _request({
                url: jobUrl.publicized_limited_edit,
                method: "POST",
                headers: {
                    'Content-Type': 'application/json'
                },
                data: params
            }).then((res) => {
                this.loading = false;
                shortTips("修改成功！");
                setTimeout(() => {
                    this.dialogVisible = false;
                }, 500);
            }).catch(err => {
                this.loading = false;
            });
        },
        handleCloseDialog() {
            this.dialogVisible = false;
            this.visibleFirmIdList = [0];
            this.companyList = [];
        }
    }
}
</script>

<style lang="scss" scope>
    .input-visible-firm.el-select {
        width: 340px;
        .el-tag.el-tag--info {
            font-size: 14px;
            height: 18px;
            line-height: 18px;
            background-color: #fff;
            border: none;
            color: #666;
            padding: 0;
            &:nth-of-type(1) {
                padding-left: 8px;
            }
            &:nth-of-type(n+2) {
                &::before {
                    content: ",";
                    margin-right: 6px;
                }
            }
            .el-tag__close {
                display: none;
            }
        }
    }
</style>