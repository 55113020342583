<template>
    <div class="my-orders-table">
        <el-table
            ref="multipleTable"
            border
            height="700"
            style="width:100%"
            tooltip-effect="light"
            :data="tableData"
            v-loading="tableDataLoading"
            @selection-change="handleSelectionChange"
        >
            <el-table-column
                fixed
                width="42"
                type="selection"
                :selectable="selectable"
                align="center"
                header-align="center"
            ></el-table-column>

            <el-table-column
                fixed
                width="110"
                align="left"
                header-align="left"
                class-name="table-header-dropdown-wrapper"
                :resizable="false"
            >
                <template slot="header" slot-scope="scope">
                    <el-dropdown
                        trigger="click"
                        class="table-header-dropdown"
                        @command="handleSortRule"
                        placement="bottom"
                    >
                        <span class="el-dropdown-link">
                            {{sortRule == 2 ? '最近推荐时间' : (tableType == 1 ? '创建时间' : '抢单时间')}}
                            <i class="el-icon-arrow-down el-icon--right"></i>
                        </span>
                        <el-dropdown-menu
                            slot="dropdown"
                            class="table-header-dropdown-menu"
                            :visible-arrow="false"
                        >
                            <el-dropdown-item
                                :class="sortRule == 1 ? 'el-dropdown-item-selected' : ''"
                                :command="1"
                            >
                                {{tableType == 1 ? '创建时间' : '抢单时间'}}
                                <font-icon class="table-body-icon sort-icon" href="#icon-orders_ic_paixu"></font-icon>
                            </el-dropdown-item>
                            <el-dropdown-item
                                :class="sortRule == 2 ? 'el-dropdown-item-selected' : ''"
                                :command="2"
                            >
                                最近推荐时间
                            </el-dropdown-item>
                        </el-dropdown-menu>
                    </el-dropdown>
                </template>
                <template slot-scope="scope">
                    <el-tooltip
                        effect="light"
                        placement="bottom"
                        :open-delay="popoverDelay"
                        :offset="110"
                        :visible-arrow="false"
                        popper-class="time-tooltip-popper"
                        :disabled="sortRule == 2 && scope.row.lastlyRecommendDate == '0001-01-01 00:00:00'"
                    >
                        <div slot="content">
                            {{ scope.row.created | formatTime }}
                        </div>
                        <span :class="scope.row.created == '0001-01-01 00:00:00' ? 'time-tooltip-show' : ''">
                            {{ scope.row.created | formatDate }}
                        </span>
                    </el-tooltip>
                </template>
            </el-table-column>

            <el-table-column
                fixed
                width="258"
                label="职位名称"
                header-align="left"
                class-name="name-cell"
                :resizable="false"
            >
                <template slot="header">
                    <select-popover
                        :enableSearch="true"
                        :enableCount="true"
                        :selectItem="headerFilter.titleSearchGroupedItems"
                        :label="'titles'"
                        :title="'职位名称'"
                        :maxSelect="5"
                        @selectChange="selectDataChange"
                    ></select-popover>
                </template>
                <template slot-scope="scope">
                    <div class="name-wrapper">
                        <template v-if="scope.row.activityTags.length > 0">
                            <font-icon
                                class="new-job-tag"
                                href="#icon-orders_label_new"
                                v-for="activityTag in scope.row.activityTags"
                                :key="activityTag.id"
                                v-if="activityTag.id === 'NewJob'"
                            ></font-icon>
                        </template>
                        <el-tooltip
                            effect="light"
                            :content="scope.row.jobName"
                            placement="top"
                            :open-delay="popoverDelay"
                            :disabled="tooltipDisabled"
                            @mouseenter.native ="handleShowTooltip($event)"
                        >
                            <span
                                class="job-detail-link"
                                @click.prevent="handleJumpDetail(scope.row, 'job', scope.$index)"
                            >
                                {{scope.row.jobName}}
                            </span>
                        </el-tooltip>
                        <font-icon class="name-wrapper-icon tip-margin" href="#icon-orders_lable_hard" v-if="scope.row.difficultDegree==1"></font-icon>
                        <font-icon class="name-wrapper-icon tip-margin" href="#icon-orders_lable_urgent" v-if="scope.row.emergencyDegree==1"></font-icon>
                        <el-tooltip
                            effect="light"
                            :content="scope.row.expectedRank"
                            placement="top"
                            :open-delay="popoverDelay"
                            v-if="scope.row.expectedRank"
                            :disabled="tooltipDisabled"
                            @mouseenter.native ="handleShowTooltip($event)"
                        >
                            <span class="expected-rank">{{scope.row.expectedRank}}</span>
                        </el-tooltip>
                        <template v-if="scope.row.activityTags.length > 0">
                            <span
                                class="activity-tag"
                                v-for="activityTag in scope.row.activityTags"
                                :key="activityTag.id"
                                v-if="activityTag.id !== 'JobEmergency' && activityTag.id !== 'JobDifficultDegree' && activityTag.id !== 'NewJob' && activityTag.id !== 'StandstillJob'"
                            >
                                {{activityTag.name}}
                            </span>
                        </template>
                        <span class="activity-tag" v-if="scope.row.jobType == 3">HR</span>
                        <span class="activity-tag" v-if="scope.row.jobType == 5">代运营</span>

                        <el-dropdown
                            ref="jobOperation"
                            placement="bottom-start"
                            v-loading="jobOperationLoading"
                            trigger="hover"
                            :hide-on-click="false">
                            <span class="operation-icon">
                                <font-icon class="table-body-icon operation-icon-default tip-margin" href="#icon-ic_act_grey"></font-icon>
                                <font-icon class="table-body-icon operation-icon-hover tip-margin" href="#icon-ic_act_green"></font-icon>
                            </span>
                            <el-dropdown-menu class="operation-menu" slot="dropdown">
                                <div v-if="tableType == 1 && (scope.row.jobStatus != 4 || scope.row.jobStatus == 4 || (scope.row.orderCount == 0 || (!scope.row.isPublic && scope.row.jobType == 2)) && scope.row.jobStatus != 4) || (scope.row.jobStatus != 4 && scope.row.jobStatus != 2)">
                                    <el-dropdown-item v-if="tableType == 1 && scope.row.jobStatus != 4" @click.native="jobOperation('changeJobStatus', scope.row)">修改职位状态</el-dropdown-item>
                                    <el-dropdown-item v-if="tableType == 1 && scope.row.jobStatus == 4" @click.native="jobOperation('copyJob', scope.row)">复制职位</el-dropdown-item>
                                    <el-dropdown-item v-if="tableType == 1 && (scope.row.orderCount == 0 || (!scope.row.isPublic && scope.row.jobType == 2)) && scope.row.jobStatus != 4" @click.native="jobOperation('editJob', scope.row)">编辑职位信息</el-dropdown-item>
                                    <el-dropdown-item @click.native="jobOperation('addItap', scope.row)">打标签</el-dropdown-item>
                                    <el-dropdown-item v-if="scope.row.jobStatus != 4 && scope.row.jobStatus != 2" @click.native="jobOperation('recommend', scope.row)">推荐候选人</el-dropdown-item>
                                    <el-dropdown-item v-if="scope.row.jobStatus != 4 && scope.row.jobStatus != 2" @click.native="jobOperation('changeRecommendation', scope.row)">变更推荐状态</el-dropdown-item>
                                </div>
                                <div v-else>
                                    <span class="no-dropdown-tip">职位{{  scope.row.jobStatus == 4 ? '已完成' : '已暂停' }}状态下无法操作</span>
                                </div>
                            </el-dropdown-menu>
                        </el-dropdown>
                    </div>
                </template>
            </el-table-column>

            <el-table-column
                label="客户公司"
                prop="customerName"
                min-width="98"
                header-align="left"
                :show-overflow-tooltip="true">
                <template slot="header">
                    <select-popover
                        :enableSearch="true"
                        :enableCount="true"
                        :selectItem="headerFilter.customerNameSearchGroupedItems"
                        :label="'customerNames'"
                        :title="'客户公司'"
                        :maxSelect="5"
                        @selectChange="selectDataChange"
                    ></select-popover>
                </template>
                <template slot-scope="scope">
                        <el-tooltip
                            effect="light"
                            :content="scope.row.customerName"
                            placement="top"
                            :open-delay="popoverDelay"
                            :disabled="tooltipDisabled"
                            @mouseenter.native ="handleShowTooltip($event)">
                            <span
                                class="customer-detail-link"
                                :class="{'no-link': (scope.row.isFromAuthorization || [4,5].includes(scope.row.jobType))}"
                                @click.prevent="handleJumpDetail(scope.row, 'customer', scope.$index)">
                                {{scope.row.customerName}}
                            </span>
                        </el-tooltip>
                </template>
            </el-table-column>

            <el-table-column
                label="职位状态"
                width="98"
                header-align="left"
            >
                <template slot="header">
                    <select-popover
                        :enableSearch="false"
                        :enableCount="true"
                        :selectItem="headerFilter.statuSearchGroupedItems"
                        :label="'status'"
                        :title="'职位状态'"
                        :maxSelect="5"
                        @selectChange="selectDataChange"
                    ></select-popover>
                </template>
                <template slot-scope="scope">
                    <div>{{scope.row.jobStatus | statusFilter }}</div>
                </template>
            </el-table-column>

            <el-table-column
                label="未处理"
                min-width="90"
                header-align="left"
            >
                <template slot="header">
                    <span class="table-header-tip-cell">
                        未处理
                        <el-tooltip
                            :content="tableType == 1 ? '当前职位存在未处理推荐，请及时处理' : '当前推荐未被及时处理，请尽快与职位创建者沟通'"
                            placement="bottom-start"
                            effect="light"
                            :visible-arrow="false"
                            popper-class="table-header-tooltip"
                        >
                            <span class="icon-question">
                                <font-icon class="table-header-tip-icon question-hover" href="#icon-talent_ql"></font-icon>
                                <font-icon class="table-header-tip-icon question-no-hover" href="#icon-talent_qd"></font-icon>
                            </span>
                        </el-tooltip>
                    </span>
                </template>
                <template slot-scope="scope">
                    <div class="dispose-wrapper" v-if="scope.row.unprocessedCount > 0">
                        <span>{{scope.row.unprocessedCount}}</span>
                        <span class="dispose-btn" v-if="tableType == 1" @click="jobOperation('goProcess', scope.row)">去处理</span>
                    </div>
                    <div v-else>
                        <span>0</span>
                    </div>
                </template>
            </el-table-column>

            <el-table-column
                width="258"
                header-align="left"
                class-name="recommend-cell"
            >
                <template slot="header">
                    <span class="table-header-tip-cell">
                        <span style="color: #FF7700;">推荐总数>></span>
                        <span style="color: #F5A623;">接受>></span>
                        <span style="color: #38BC9D;">面试>></span>
                        <span style="color: #6FBBFB;">offer</span>
                        <el-tooltip
                            :content="tableType == 1 ? '当前职位在选择时间范围内的全部推荐情况' : '在所选时间范围内，当前登录用户的单个职位的全部推荐情况'"
                            placement="bottom-start"
                            effect="light"
                            :visible-arrow="false"
                            popper-class="table-header-tooltip"
                        >
                            <span class="icon-question">
                                <font-icon class="table-header-tip-icon question-hover" href="#icon-talent_ql"></font-icon>
                                <font-icon class="table-header-tip-icon question-no-hover" href="#icon-talent_qd"></font-icon>
                            </span>
                        </el-tooltip>
                    </span>
                </template>
                <template slot-scope="scope">
                    <div>
                        {{scope.row.recommendCount}}推 ->
                        {{scope.row.acceptedCount}}受 ->
                        {{scope.row.interviewCount}}面 ->
                        {{scope.row.offerCount}} Offer
                    </div>
                </template>
            </el-table-column>

            <el-table-column
                label="抢单"
                align="left"
                width="56"
                header-align="left">
                <template slot-scope="scope">
                    <div>{{scope.row.orderCount}}</div>
                </template>
            </el-table-column>

            <el-table-column
                align="left"
                min-width="165"
                header-align="left"
                label="职位基本要求"
            >
                <template slot-scope="scope">
                    <el-tooltip
                        effect="light"
                        placement="top"
                        :open-delay="popoverDelay"
                        :disabled="tooltipDisabled"
                        @mouseenter.native ="handleShowTooltip($event)">
                        <div slot="content">
                            <span v-if="scope.row.minYearOfExperience != undefined">{{scope.row.minYearOfExperience | yearOfExperienceFilter}} | </span>
                            <span v-if="scope.row.degree != undefined">{{scope.row.degree | degreeFilter}} | </span>
                            <span v-if="scope.row.recruitingCount">需{{scope.row.recruitingCount}}人</span>
                        </div>
                        <span class="text-ellipsis require-list">
                            <span v-show="scope.row.minYearOfExperience != undefined">{{scope.row.minYearOfExperience | yearOfExperienceFilter}} | </span>
                            <span v-show="scope.row.degree != undefined">{{scope.row.degree | degreeFilter}} | </span>
                            <span v-show="scope.row.recruitingCount != 0">需{{scope.row.recruitingCount}}人</span>
                        </span>
                    </el-tooltip>
                </template>
            </el-table-column>

            <el-table-column
                label="佣金情况"
                min-width="120"
                header-align="left"
                v-if="tableType == 2"
                class-name="table-header-dropdown-wrapper"
            >
                <template slot="header" slot-scope="scope">
                    <el-dropdown
                        trigger="click"
                        placement="bottom-start"
                        v-if="tableData.length > 0"
                        class="table-header-dropdown"
                        @command="handleCommissionValueType"
                    >
                        <span class="el-dropdown-link">
                            佣金情况
                            <i class="el-icon-arrow-down el-icon--right"></i>
                        </span>
                        <el-dropdown-menu slot="dropdown" class="table-header-dropdown-menu" :visible-arrow="false">
                            <el-dropdown-item
                                :class="commissionValueType == 0 ? 'el-dropdown-item-selected' : ''"
                                :command="0">
                                全部
                            </el-dropdown-item>
                            <el-dropdown-item
                                :class="commissionValueType == 2 ? 'el-dropdown-item-selected' : ''"
                                :command="2">
                                固定佣金
                            </el-dropdown-item>
                            <el-dropdown-item
                                :class="commissionValueType == 1 ? 'el-dropdown-item-selected' : ''"
                                :command="1">
                                按比例佣金
                            </el-dropdown-item>
                        </el-dropdown-menu>
                    </el-dropdown>
                    <span v-else>佣金情况</span>
                </template>
                <template slot-scope="scope">
                    <el-tooltip
                        effect="light"
                        placement="top"
                        :open-delay="popoverDelay"
                        v-if="scope.row.commissionType == 0 && scope.row.minCommission >= 100000"
                    >
                        <div slot="content">
                            {{scope.row.minCommission | commissionFilter}}k-{{scope.row.maxCommission | commissionFilter}}k({{scope.row.commissionValue > 100 ? 0 : scope.row.commissionValue}}%)
                        </div>
                        <div class="text-ellipsis">
                            {{scope.row.minCommission | commissionFilter}}k-{{scope.row.maxCommission | commissionFilter}}k({{scope.row.commissionValue > 100 ? 0 : scope.row.commissionValue}}%)
                        </div>
                    </el-tooltip>
                    <div v-else>
                         <span v-if="scope.row.commissionType == 1">
                            {{scope.row.commissionValue | commissionFilter}}k
                        </span>
                        <span v-else>
                            {{scope.row.minCommission | commissionFilter}}k-{{scope.row.maxCommission | commissionFilter}}k({{scope.row.commissionValue > 100 ? 0 : scope.row.commissionValue}}%)
                        </span>
                   </div>
                </template>
            </el-table-column>

            <el-table-column
                label="标签"
                min-width="100"
                header-align="left"
            >
                <template slot="header">
                    <select-popover
                        :enableSearch="true"
                        :enableCount="true"
                        :selectItem="headerFilter.tagSearchGroupedItems"
                        :label="'tags'"
                        :title="'标签'"
                        :maxSelect="5"
                        @selectChange="selectDataChange"
                    ></select-popover>
                </template>
                <template slot-scope="scope">
                    <add-itap-popover
                        :tagData="scope.row"
                        :isResume="false"
                        v-if="scope.row.tags.length > 0"
                    ></add-itap-popover>
                    <span class="cursor-pointer" v-else @click="jobOperation('addItap', scope.row)">
                        <font-icon class="table-body-icon-big icon-inquiry" href="#icon-talent_lable"></font-icon>
                        <span class="color-warn">&nbsp;添加</span>
                    </span>
                </template>
            </el-table-column>

            <template slot="empty">
                <div>
                    <span class="empty-img"></span>
                    <p class="empty-data">暂无数据</p>
                </div>
            </template>
        </el-table>

        <table-page-tab
            :filterIndex="tableType - 1"
            :pageTabs="pageTabJson"
        ></table-page-tab>

        <!-- footer -->
        <div class="work-table-footer">
            <div class="footer-left">
                <div class="footer-select">
                    <input hidden v-model="allSelect" type="checkbox">
                    <span class="all-select-checkbox" @click="handleAllSelect"></span>
                    <span>全选</span>
                    <span class="select-count">已选择 <span class="color-orange">{{multipleSelection.length}}</span> 个职位</span>
                </div>
                <batch-operation
                    ref="batchOperation"
                    :selectList="multipleSelection"
                    :tableType="tableType"
                    :canRecord="canRecord"
                ></batch-operation>
            </div>
            <el-pagination
                class="el-pagination-workTable"
                :current-page="page.current"
                :page-sizes="[20, 30, 50]"
                :page-size="page.size"
                layout="total, sizes, prev, pager, next, slot"
                :total="page.total"
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange">
                <span class="pagination-text">
                    <span>前往<el-input v-model="pagerJump" @keyup.enter.native="handlePagerJump"></el-input>页</span>
                    <span @click="handlePagerJump">跳转</span>
                </span>
            </el-pagination>
        </div>
    </div>
</template>

<script>
import moment from 'moment';
import SelectPopover from '#/component/common/select-popover.vue';
import AddItapPopover from '@src/component/common/addItapPopover.vue';
import TablePageTab from '#/component/workTable/table-page-tab.vue';
import BatchOperation from '../component/batchOperation.vue';
import DegreeKeyValue from '#/js/config/degreeKeyValue.json';
const JobStatus = {
    1: "招聘中",
    2: "已暂停",
    3: "沉睡职位",
    4: "已完成"
};
const YearOfExperience = {
    0: "经验不限",
    1: "1-3年",
    3: "3-5年",
    5: "5-10年",
    10: "10年以上"
};

    export default {
        name: "MyOrdersTable",
        componentName: "MyOrdersTable",
        components:{
            SelectPopover,
            AddItapPopover,
            TablePageTab,
            BatchOperation
        },
        props:{
            tableType: Number,
            page: {
                type: Object,
                default() {
                    return {
                        current: 0,
                        size: 20,
                        total:0
                    }
                }
            },
            tableData: Array,
            headerFilter: {},
            searchId: String
        },
        watch: {

        },
        data(){
            return{
                popoverDelay: 300,
                tableDataLoading: false,
                sortRule: 1,
                multipleSelection: [],
                pagerJump: 0,
                pageTabJson: [{
                    enTitle: "Orders",
                    cnTitle: "我的订单",
                    path: "/myOrders/grabbedJob",
                    title: "我抢单的职位",
                    tabIndex: 1,
                    aliveComponent: 'MyOrdersGrabbedJob'
                }, {
                    enTitle: "Orders",
                    cnTitle: "我的订单",
                    path: "/myOrders/createdJob",
                    title: "我创建的职位",
                    tabIndex: 0,
                    aliveComponent: 'MyOrdersCreatedJob'
                }, {
                    enTitle: "Orders",
                    cnTitle: "我的订单",
                    path: "/myOrders/sharedJob",
                    title: "我分享的职位",
                    tabIndex: 2,
                    aliveComponent: 'MyOrdersSharedJob'
                }, {
                    enTitle: "Orders",
                    cnTitle: "我的订单",
                    path: "/myOrders/a2aAuditJob",
                    title: "A2A职位审批",
                    tabIndex: 3,
                    aliveComponent: 'MyOrdersA2aAuditJob'
                }],
                jobOperationLoading: false,
                tooltipDisabled: true,
                commissionValueType: 0,
                canRecord: false
            }
        },
        computed:{
            allSelect(){
                return this.tableData.length > 0 ? this.tableData.length == this.multipleSelection.length : false;
            },
        },
        filters:{
            formatDate(val) {
                return val == '0001-01-01 00:00:00' ? '暂无推荐' : moment(val).format('YYYY-MM-DD');
            },
            formatTime(val) {
                return moment(val).format('YYYY-MM-DD HH:mm:ss');
            },
            statusFilter(val) {
                return val ? JobStatus[val] : '-';
            },
            yearOfExperienceFilter(val) {
                return YearOfExperience[val] ? YearOfExperience[val] : '经验不限';
            },
            degreeFilter(val) {
                return DegreeKeyValue[val];
            },
            commissionFilter(val) {
                return val < 0 ? 0 : parseInt(val / 1000);
            }
        },
        created(){

        },
        mounted() {

        },
        methods:{
            selectable(row, index) {
                // console.log(row);
                if(row.jobStatus == 4) {
                    return false;
                } else {
                    return true;
                }
            },
            handleSelectionChange(val){
                this.multipleSelection = val;
            },
            handleAllSelect() {
                this.$refs.multipleTable.toggleAllSelection();
            },
            handleSortRule(value) {
                this.sortRule = value;
                this.$emit('sortRuleChange', value);
            },
            selectDataChange(label, result){
                if(result.length > 0){
                    return this.$emit('handleAddLabel', label, result, true);
                } else {
                    return this.$emit("handleCleanLabel", label);
                }
            },
            handleCurrentChange(val){
                this.$emit('page-change',{current:val,type:"current"})
            },
            handleSizeChange(val){
                if(window.localStorage) {
                    localStorage.setItem('LBD_TABLE_PAGE_SIZE',val);
                }
                this.$emit('page-change',{size:val,type:"size"})
            },
            handlePagerJump(){
                let currentPager = Number(this.pagerJump),
                currentPageTotal = Math.ceil(this.page.total/this.page.size);
                if(currentPager > 0 && currentPager <= currentPageTotal){
                    this.$emit('page-change',{current:currentPager,type:"current"})
                }
            },
            scrollTop(){
                document.querySelector('.el-table__body-wrapper').scrollTop = 0;
            },
            handleJumpDetail(item, type, index) {
                this.$emit('jump-detail', item);
                switch(type) {
                    case 'job':
                        // // if(item.jobType == 3) {
                        // //     window.open(`/Headhunting/MyCompany.html#/Job/${item.jobId}?curRecommendJobType=3`, '_blank');
                        // // } else {
                        // //     window.open(`/Headhunting/MyCompany.html#/Job/${item.jobId}`, '_blank');
                        // // }
                        // window.open(`/Headhunting/MyCompany.html#/Job/${item.jobId}`, '_blank');
                        window.open(`/Headhunting/#/Job/${item.jobId}`, '_blank');
                        break;
                    case 'customer':
                        if([4,5].includes(item.jobType)) return;
                        if(item.jobType == 3) {
                            window.open(`/Headhunting/#/hrFirm?customerId=${item.firmId}`);
                        } 
                        if(item.jobType == 2 && !item.isFromAuthorization) {
                            // window.open(`/Headhunting/MyCompany.html#/Customer/${item.customerId}`, '_blank');
                            window.open(`/#/Customer/${item.customerId}`, '_blank');
                        }

                        break;
                }

                if(type == 'job') {
                    _tracker.track('MyOrdersSearchResultClick', JSON.stringify({
                        searchId: this.searchId,
                        jobJd: item.jobId,
                        index: index
                    }));
                }
            },
            handleCommissionValueType(type) {
                let label = 'commissionType';
                let result = [{
                    label: '',
                    text: ''
                }];
                result[0].text = type;
                if(type == 0) {
                    result = [];
                } else if(type == 1) {
                    result[0] = {
                        label: '按比例佣金',
                        text: '按比例佣金'
                    };
                } else {
                    result[0] = {
                        label: '固定佣金',
                        text: '固定佣金'
                    };
                }
                this.$emit('commissionValueTypeChange', type);
                this.selectDataChange(label, result);
            },
            jobOperation(command, dataJson) {
                if(command == 'copyJob') {
                    // location.href = `/#/jobCreate/copy?jobId=${dataJson.jobId}`;
                    window.open(`/#/jobCreate/copy?jobId=${dataJson.jobId}`);
                } else if(command != 'editJob') {
                    this.$emit('job-operation', command, dataJson);
                } else {
                    _tracker.track('MyOrdersCreatedJobOperation', JSON.stringify({
                        operationType: 'editJob',
                        operationText: '编辑职位信息'
                    }));
                    this.editJob(dataJson);
                }
            },
            editJob(jobData) {
                // window.open(`/Headhunting/MyCompany.html#/jobCreate/single?jobId=${jobData.jobId}`, "_blank");
                window.open(`/Headhunting/MyCompany.html#/jobCreate/single?jobId=${jobData.jobId}`, "_blank");
            },
            handleShowTooltip(event){
                let cell = event.target;
                if(cell.classList.contains('require-list')) {
                    setTimeout(() => {
                        this.tooltipDisabled = event.fromElement.offsetWidth > cell.offsetWidth;
                    }, 300);
                } else {
                    setTimeout(() => {
                        this.tooltipDisabled = cell.clientWidth == cell.scrollWidth;
                    }, 300);
                }
            }
        }
    }

</script>

<style lang="scss" scoped>
@import "#/css/scss/variables.scss";

.color-orange {
    color: $orange;
}
.color-warn{
    color: #FF6564;
}
.text-ellipsis{
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.cursor-pointer {
    cursor: pointer;
}
.my-orders-table /deep/ {
    height: 60.6% !important;
    padding: 0 20px;
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    letter-spacing: 0px;
    background:url('~@src/assets/images/home/home_bg.png') bottom center no-repeat;
    .el-table {
        overflow-y: auto;
        color: #666;
        z-index: 0;
        .el-table__body-wrapper, .el-table__fixed-body-wrapper {
            padding-bottom: 1px;
        }
        .el-loading-mask {
            z-index: 900;
        }
        .el-table__fixed::before {
            display: none;
        }
        th {
            padding: 0 0 0 13px;
            height: 42px;
            line-height: 42px;
            background-color: #E8E8E8;
            border-color: #CCC;
            color: #666;
            &:nth-of-type(2) {
                padding-left: 2px;
                .el-icon--right {
                    margin-left: 0;
                }
            }
            &.recommend-cell {
                .icon-question {
                    margin-left: 5px;
                }
            }
            &.gutter{
                display: none;
            }
            &.is-center{
                padding: 0;
            }
            .cell {
                padding: 0;
                .el-checkbox {
                    display: none;
                }
            }
            .title-slot{
                padding: 0 !important;
            }
            div{
                display: block;
            }
        }
        td {
            padding: 0;
            height: 40px;
            line-height: 40px;
            .cell {
                white-space: nowrap;
                padding-left: 13px;
                .el-checkbox__input.is-checked .el-checkbox__inner {
                    background-color: #fff;
                    border-color: $primary;
                    &::after {
                        border-color: $primary;
                    }
                }
                &.el-tooltip {
                    div {
                        text-overflow: ellipsis;
                        overflow: hidden;
                    }
                }
                .table-body-icon-mr {
                    margin-right: 4px;
                }
                .expected-rank, .activity-tag {
                    height: 16px;
                    margin-left: 6px;
                    padding: 0 3px;
                    border-radius: 4px;
                    background: $primary;
                    font-size: 12px;
                    line-height: 16px;
                    color: #fff;
                }
                .expected-rank {
                    max-width: 68px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
                .activity-tag {
                    background: #39f;
                }
                .time-tooltip-show {
                    &:hover {
                        color: $primary;
                    }
                }
                .dispose-wrapper {
                    .dispose-btn {
                        display: none;
                        color: $primary;
                        margin-left: 10px;
                        cursor: pointer;
                    }
                    &:hover {
                        .dispose-btn {
                            display: inline-block;
                        }
                    }
                }
            }
            &.is-center{
                .cell{
                    padding: 0 3px;
                }
            }
            &:nth-of-type(2) .cell{
                padding-left: 2px;
            }
        }
        .el-table__row {
            .el-checkbox__inner {
                border-color: #999;
            }
        }
        .name-cell{
            position: relative;
            .cell {
                display: flex;
                align-items: center;
                .tip-margin{
                    margin-left: 5px;
                    .el-loading-spinner {
                        margin-top: -12px;
                        .circular {
                            height: 100%;
                            width: 100%;
                        }
                    }
                }
            }
            .name-wrapper {
                width: auto;
                max-width: 234px;
                display: inline-flex;
                align-items: center;
                .job-detail-link {
                    min-width: 20px;
                    max-width: 128px;
                }
                .name-wrapper-icon {
                    width: 17px;
                    height: 17px;
                }
            }
            .new-job-tag {
                width: 26px;
                height: 16px;
                position: absolute;
                top: -1px;
                left: -1px;
            }
        }
        .job-detail-link, .customer-detail-link {
            color: #666;
            float: left;
            flex-grow: 1;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            cursor: pointer;
            &:hover {
                color: $primary;
            }
        }
        .customer-detail-link {
            max-width: 136px;
            &.no-link {
                cursor: default;
                &:hover {
                    color: #666;
                }
            }
        }
        .el-table__empty-block{
            min-height: 250px;
            .empty-data{
                display: block;
            }
        }
        .el-table-column--selection {
            border-right: none;
        }
        .el-table__empty-block {
            .el-table__empty-text {
                line-height: inherit;
            }
            .empty-img {
                display: inline-block;
                background: url('~@src/assets/images/home/icon_no_data.svg') no-repeat;
                width: 240px;
                height: 228px;
            }
            .empty-data {
                font-size: 16px;
                line-height: 21px;
                margin-bottom: 0px;
            }
        }

    }
}
</style>
<style lang="scss">
.el-tooltip__popper.is-light.table-header-tooltip{
    margin-top: 5px;
    margin-left: 0;
    padding: 12px 20px;
    font-size: 12px;
    color: #666;
    max-width: 380px;
}
.el-tooltip__popper.is-light.time-tooltip-popper {
    padding: 10px 20px;
    font-size: 14px;
    margin-top: 2px;
}
.table-header-dropdown-menu {
    .sort-icon {
        margin-left: 5px;
    }
}

.el-dropdown-menu {
    .no-dropdown-tip {
        display: inline-block;
        padding: 10px;
    }
}
</style>
