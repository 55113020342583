<template>
    <el-container class="slider-dialog" :class="activeClass" v-loading="isloading">
        <div class="header-menu">
            <div class="pull-right">
                <font-icon class="lbd-btn__close" href="#icon-ic_off_tj1" @click.native="handleClose"></font-icon>
            </div>
        </div>

        <div class="header-title">
            <span>{{ job.customerName }}</span> <span>{{ job.jobName }}</span>
            <span>{{ status === 'all' ? '变更推荐状态' : '未处理' }}（<span class="color-orange">{{ page.total }}</span>）</span>
        </div>

        <div class="list">
            <candidate-card
                v-if="listData.length > 0"
                v-for="(item, index) in listData"
                :key="index"
                :enable-bubble="true"
                :card-data="item"
                :class="item.status === 1024 || item.status === 32 || (status !== 'all' && item.status === 1) ? 'remove' : ''"
                @show-dialog="showRecommendDialog">

            </candidate-card>

            <div
                v-if="listData.length === 0"
                class="default">
                <div class="empty-img">

                </div>
                <span>暂无数据</span>
            </div>
        </div>

        <div class="footer">
            <el-pagination :current-page="page.current" class="el-pagination-workTable"
                           layout="total, prev, pager, next, slot" :total="page.total"
                           :page-size="page.take"
                           @current-change="handleCurrentChange">
                    <span class="pagination-text">
                        <span>前往<el-input v-model="pagerJump" @keyup.enter.native="handlePagerJump"></el-input>页</span>
                        <span @click="handlePagerJump">跳转</span>
                    </span>
            </el-pagination>
        </div>
        <recommend-dialog-list ref="recommendDialog" @update-list="updateList"></recommend-dialog-list>
    </el-container>
</template>

<script>
    import CandidateCard from '#/component/common/card/candidate-card';
    import recommendDialogList from '#/component/workTable/recommend-dialog-list.vue';
    import myOrdersService from '#/js/service/myOrdersService.js';
    import emitter from '#/js/mixins/emitter.js';

    export default {
        name: "slider-dialog",
        componentName: "sliderialog",
        components: {
            CandidateCard,
            recommendDialogList
        },
        mixins: [emitter],
        data() {
            return {
                isActive: false,
                page: {
                    current: 1,
                    take: 7,
                    total: 0
                },
                listData: [],
                isloading: false,
                pagerJump: 0
            }
        },
        props: {
            status: {
                default: 0,
                type: [Number, String],
            },
            job: {
                default: {},
                type: Object
            }
        },
        computed: {
            activeClass() {
                return this.isActive ? 'active' : '';
            }
        },
        methods: {
            handleShow() {
                this.isActive = true;
                this.page.current = 1;
                setTimeout(()=>{
                    this.getListData();
                }, 300)
            },
            handleClose() {
                this.isActive = false;
                this.$emit('update-item', this.job);
            },
            handleCurrentChange(value) {
                this.page.current = value;
                this.getListData();
            },
            handlePagerJump() {
                this.page.current = this.pagerJump;
                this.getListData();
            },
            getListData() {
                if (!this.job.jobId) {
                    return false;
                }
                this.isloading = true;
                myOrdersService
                    .getRecommendation({
                        jobId: this.job.jobId,
                        take: this.page.take,
                        start: (this.page.current - 1) * this.page.take,
                        status: this.status === 'all' ? undefined : this.status
                    })
                    .then(res => {
                        this.listData = res.list;
                        this.page.total = res.total;
                    },err => {
                        this.listData = [];
                        this.page.total = 0;
                    }).finally(() => {
                        this.isloading = false;
                    })
            },
            //展示弹窗操作
            showRecommendDialog(index, nextStatus, recommend, btnText) {
                this.activeRecommend = recommend;
                this.$refs.recommendDialog.setStatus(recommend.status);
                switch (nextStatus) {
                    case 1:
                        //接受
                        this.$refs.recommendDialog.$refs.acceptDialog.showHandle(recommend.id, btnText, recommend.isShowMoreText || false);
                        break;
                    case 4:
                        //进入面试
                        this.$refs.recommendDialog.setStatus(recommend.status, 4);
                        this.$refs.recommendDialog.$refs.stageDialog.showHandle(recommend.id, btnText);
                        break;
                    case 2:
                        //拒绝
                        this.$refs.recommendDialog.$refs.rejectDialog.showHandle(recommend.id, btnText);
                        break;
                    case 5:
                        //安排面试
                        this.$refs.recommendDialog.$refs.interviewDialog.showHandle({
                            "btnText": btnText,
                            "id": recommend.id,
                            "realName": recommend.candidateName,
                            "jobName": recommend.job,
                            "headhunterRealName": recommend.headhunterRealName || '',
                            "headhunterNickname": recommend.headhunterNickname || ''
                        });
                        break;
                    case 7:
                        //面试反馈
                        this.$refs.recommendDialog.$refs.processDialog.showHandle(recommend.interviewId, btnText);
                        break;
                    case 8:
                        // offer
                        this.$refs.recommendDialog.$refs.offerDialog.showHandle(recommend.id, true, btnText);
                        break;
                    case 88:
                        // 一键offer
                        this.$refs.recommendDialog.$refs.offerDialog.showHandle(recommend.id, false, btnText);
                        break;
                    case 16:
                        //入职
                        this.$refs.recommendDialog.setStatus(recommend.status, 16);
                        this.$refs.recommendDialog.$refs.stageDialog.showHandle(recommend.id, btnText);
                        break;
                    case 32:
                        // 离职
                        this.$refs.recommendDialog.setStatus(recommend.status, 32);
                        this.$refs.recommendDialog.$refs.stageDialog.showHandle(recommend.id, btnText);
                        break;
                    case 10241:
                        // 已接受待推进面试状态淘汰候选人
                        this.$refs.recommendDialog.$refs.obsoleteDialog.showHandle(recommend.id, 1, btnText);
                        break;
                    case 102440:
                        // 面试待安排状态淘汰候选人
                        this.$refs.recommendDialog.$refs.obsoleteDialog.showHandle(recommend.id, 40, btnText);
                        break;
                    case 10244:
                        // 面试已反馈状态淘汰候选人
                        this.$refs.recommendDialog.$refs.obsoleteDialog.showHandle(recommend.id, 4, btnText);
                        break;
                    case 10248:
                        // offer状态淘汰候选人
                        this.$refs.recommendDialog.$refs.obsoleteDialog.showHandle(recommend.id, 8, btnText);
                        break;
                    case 100:
                        //推荐至用户：姓名展开行中若该操作按钮消失，则该姓名所在的记录的推荐至客户按钮也要消失
                        //this.tableData[index].isRecommendToCustomer = true;
                        break;
                }
            },
            updateList(...params) {
                var cardIndex;
                this.listData.find((item, index) => {
                    if(item.id === this.activeRecommend.id) {
                        item.status = params[2];
                        item.interviewId = params[3] ? params[3] : item.interviewId;
                        cardIndex = index;
                    }
                });
                if(params[2] === 32 || params[2] === 1024 || this.status !== 'all'){
                    this.page.total = this.page.total - 1;
                    setTimeout(() => {
                        this.listData.splice(cardIndex, 1);
                    }, 700);
                }
            }
        }
    }
</script>

<style lang="scss">
    .slider-dialog {
        display: flex;
        flex-direction: column;
        position: absolute;
        right: -620px;
        padding: 5px 10px 0px 10px;
        width: 600px;
        height: 100%;
        background-color: #fff;
        box-shadow: -6px 0 6px 0 rgba(0, 0, 0, 0.20);
        transition: .3s;

        &.active {
            right: 0;
        }

        .header-menu {
            margin-bottom: 5px;

            &:before, &:after {
                content: '';
                display: table;
                clear: both;
            }
        }

        .pull-right {
            float: right;
        }

        .color-orange {
            color: $orange;
        }

        .lbd-btn__close {
            padding: 4px;
            background-color: #F5F5F5;
            font-size: 20px;
            border-radius: 50%;
            cursor: pointer;
        }

        .header-title {
            padding: 4px 10px;
            background: #F5F5F5;
            font-size: 16px;
            font-weight: bold;
            line-height: 21px;

            & > span {
                margin-right: 20px;
            }
        }

        .footer {
            min-height: 75px;
        }

        .list {
            overflow-y: auto;
            overflow-x: hidden;

            .default{
                .empty-img{
                    margin: 40px auto 10px auto;
                    background: url('~@src/assets/images/home/icon_no_data.svg') no-repeat;
                    width: 240px;
                    height: 228px;
                }

                >span{
                    display: block;
                    text-align: center;
                    font-size: 16px;
                    line-height: 21px;
                }
            }

            .candidate-card{
                position: relative;
                &.remove {
                    animation: removeCard .6s linear forwards;
                }
            }
        }

        .el-pagination.el-pagination-workTable {
            float: right;
            padding: 0;
            font-weight: normal;

            .btn-next, .btn-prev {
                height: 28px;
                border: 1px solid;
            }

            .btn-prev {
                border-right: none;
                border-radius: 4px 0 0 4px;
            }

            .btn-next {
                border-left: none;
                border-radius: 0 4px 4px 0;
            }

            .el-pagination__sizes .el-input {
                width: 87px;

                .el-input__inner {
                    color: #666;

                    .el-select__caret {
                        color: #999;
                    }
                }
            }

            .el-pager {
                border-top: 1px solid;
                border-bottom: 1px solid;

                li {
                    min-width: 16.5px;
                    height: 26px;
                    line-height: 26px;
                    font-size: 14px;
                }
            }

            .btn-next, .btn-prev, .el-pager {
                border-color: #DDDDDD;
            }

            .el-input__inner, .el-pagination__total, .el-pagination__jump {
                height: 28px;
                line-height: 28px;
                font-size: 14px;
            }

            .pagination-text {
                color: #999;

                span {
                    height: 28px;
                    line-height: 28px;
                    font-size: 14px;
                    margin-left: 30px;

                    .el-input {
                        width: 48px;
                        margin: 0 5px;

                        &__inner {
                            height: 28px;
                            line-height: 28px;
                            padding: 0 5px;
                        }
                    }

                    &:nth-of-type(2) {
                        margin-left: 5px;
                        color: $primary;
                        cursor: pointer;
                    }
                }
            }
        }

        .el-pagination {
            position: absolute;
            bottom: 30px;
            left: 50%;
            transform: translateX(-50%);
            margin-top: 24px;
        }
    }

    @keyframes removeCard {
        0% {
            right: 0;
            height: 107px;
            padding: 16px 0;
        }
        50% {
            right: -600px;
            height: 107px;
            padding: 16px 0;
        }
        100%{
            right: -600px;
            height: 0;
            padding: 0;
        }
    }
</style>
