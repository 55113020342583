var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "work-table-container" }, [
    _c(
      "div",
      { staticClass: "my-orders-wrapper" },
      [
        _c("selectWrapper", {
          ref: "selectWrapper",
          attrs: { tableType: _vm.tableType },
          on: {
            timeLimitChange: _vm.timeLimitChange,
            bubbleClick: _vm.bubbleClick,
            bubbleClear: _vm.bubbleClear,
          },
        }),
        _c("selectLabel", {
          ref: "searchLabel",
          attrs: {
            count: _vm.page.total,
            labelList: _vm.labelList,
            orderLabelShowList: _vm.orderLabelShowList,
          },
          on: { handleCleanLabel: _vm.handleCleanLabel },
        }),
        _c("MyOrdersTable", {
          ref: "myOrdersTable",
          attrs: {
            tableType: _vm.tableType,
            tableData: _vm.tableData,
            headerFilter: _vm.headerFilter,
            page: _vm.page,
            searchId: _vm.searchId,
          },
          on: {
            sortRuleChange: _vm.handleSortRule,
            handleAddLabel: _vm.handleAddLabel,
            handleCleanLabel: _vm.handleCleanLabel,
            "page-change": _vm.handlePageChange,
            "job-operation": _vm.jobOperationDetail,
            commissionValueTypeChange: _vm.handleCommissionValueType,
          },
        }),
        _c("add-itap-dialog", {
          ref: "addItapDialog",
          attrs: { isResume: false, type: "myOrders" },
          on: {
            "update-list": function ($event) {
              return _vm.search(true)
            },
            "update-item": _vm.updateItem,
          },
        }),
        _c("slider-dialog", {
          ref: "sliderDialog",
          attrs: {
            status: this.sliderDialog.status,
            job: this.sliderDialog.job,
          },
          on: { "update-item": _vm.updateItem },
        }),
        _c("job-status-dialog", {
          ref: "jobStatusDialog",
          on: {
            "update-list": function ($event) {
              return _vm.search(true)
            },
            "update-item": _vm.updateItem,
            showProjectCreateDialog: _vm.showProjectCreateDialog,
          },
        }),
        _c("project-create-dialog", {
          ref: "projectCreateDialog",
          on: { "refresh-project-list": _vm.refreshPorjectList },
        }),
        _c("transfer-job-dialog", {
          ref: "transferJobDialog",
          attrs: { isAllMembers: true },
          on: {
            "update-list": function ($event) {
              return _vm.search(true)
            },
          },
        }),
        _c("recommend-dialog", { ref: "recommendDialog", attrs: { type: 1 } }),
        _c("job-publish-dialog", {
          ref: "jobPublishDialog",
          attrs: { type: 1 },
          on: {
            "update-list": function ($event) {
              return _vm.search(true)
            },
          },
        }),
        _c("edit-visible-firm-dialog", { ref: "editVisibleFirmDialog" }),
        _c("job-share-edit-dialog", {
          ref: "jobShareEditDialog",
          attrs: { isSingleEdit: false },
          on: {
            shareJobCard: _vm.showShareJobCardDialog,
            firstEditPersonalInfo: _vm.showJobPersonalInfoDialog,
          },
        }),
        _c("share-job-card-dialog", { ref: "shareJobCardDialog" }),
        _vm.isFirstAddMyShareJob
          ? _c("job-personal-info-dialog", {
              ref: "jobPersonalInfoDialog",
              on: { personalInfoEditComfirm: _vm.updatePersonalInfo },
            })
          : _vm._e(),
        _c("media-record-entry", { ref: "mediaRecordEntry" }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }