<template>
<div class="work-table-container">
    <div class="my-orders-wrapper">
        <selectWrapper
            ref="selectWrapper"
            :tableType="tableType"
            @timeLimitChange="timeLimitChange"
            @bubbleClick="bubbleClick"
            @bubbleClear="bubbleClear"
        ></selectWrapper>
        <selectLabel
            ref="searchLabel"
            :count="page.total"
            :labelList="labelList"
            :orderLabelShowList="orderLabelShowList"
            @handleCleanLabel="handleCleanLabel"
        ></selectLabel>
        <MyOrdersTable
            ref="myOrdersTable"
            :tableType="tableType"
            :tableData="tableData"
            :headerFilter="headerFilter"
            :page="page"
            :searchId="searchId"
            @sortRuleChange="handleSortRule"
            @handleAddLabel="handleAddLabel"
            @handleCleanLabel="handleCleanLabel"
            @page-change="handlePageChange"
            @job-operation="jobOperationDetail"
            @commissionValueTypeChange="handleCommissionValueType"
        ></MyOrdersTable>
        <add-itap-dialog
            ref="addItapDialog"
            :isResume="false"
            @update-list="search(true)"
            @update-item="updateItem"
            type="myOrders"
        ></add-itap-dialog>
        <slider-dialog
            ref="sliderDialog"
            :status="this.sliderDialog.status"
            :job="this.sliderDialog.job"
            @update-item="updateItem"
            ></slider-dialog>
        <job-status-dialog 
            ref="jobStatusDialog" 
            @update-list="search(true)" 
            @update-item="updateItem"
            @showProjectCreateDialog="showProjectCreateDialog"
        ></job-status-dialog>
        <project-create-dialog ref="projectCreateDialog" @refresh-project-list="refreshPorjectList"></project-create-dialog>
        <transfer-job-dialog ref="transferJobDialog" @update-list="search(true)" :isAllMembers="true"></transfer-job-dialog>
        <recommend-dialog
            ref="recommendDialog"
            :type="1"
        ></recommend-dialog>
        <job-publish-dialog
            ref="jobPublishDialog"
            :type="1"
            @update-list="search(true)"
        ></job-publish-dialog>
        <edit-visible-firm-dialog ref="editVisibleFirmDialog"></edit-visible-firm-dialog>
        <job-share-edit-dialog
            ref="jobShareEditDialog"
            :isSingleEdit="false"
            @shareJobCard="showShareJobCardDialog"
            @firstEditPersonalInfo="showJobPersonalInfoDialog"
        ></job-share-edit-dialog>
        <share-job-card-dialog ref="shareJobCardDialog"></share-job-card-dialog>
        <job-personal-info-dialog
            ref="jobPersonalInfoDialog"
            v-if="isFirstAddMyShareJob"
            @personalInfoEditComfirm="updatePersonalInfo"
        ></job-personal-info-dialog>
        <media-record-entry ref="mediaRecordEntry"></media-record-entry>
    </div>
</div>
</template>

<script>
    import SelectWrapper from './selectWrapper.vue';
    import SelectLabel from './selectLabel.vue';
    import MyOrdersTable from './myOrdersTable.vue';
    import SliderDialog from '../component/slider-dialog.vue';
    import MyOrdersService from '#/js/service/myOrdersService.js';

    import JobStatusDialog from '@src/component/common/dialog/jobStatusDialog.vue';
    import ProjectCreateDialog from '#/views/project/component/create-project-dialog.vue';
    import TransferJobDialog from '@src/component/common/dialog/transferJobDialog.vue'
    import emitter from '#/js/mixins/emitter.js';
    import AddItapDialog from '@src/component/common/dialog/addItapDialog.vue';
    import RecommendDialog from '@src/component/common/dialog/recommendDialog.vue';
    import moment from 'moment';
    import JobPublishDialog from '#/component/common/dialog/jobPublishDialog.vue';
    import EditVisibleFirmDialog from '../component/editVisibleFirmDialog.vue';
    import JobShareEditDialog from '#/component/common/dialog/job-share-edit-dialog.vue';
    import ShareJobCardDialog from '#/component/common/dialog/share-job-card-dialog.vue';
    import JobPersonalInfoDialog from '#/component/common/dialog/job-personal-info-dialog.vue';
    import MediaRecordEntry from '#/component/mediaRecord/mediaRecordEntry.vue';
    import MediaService from '#/js/service/mediaService.js';
    export default {
        name: "myOrdersWrapper",
        componentName: "MyOrdersWrapper",
        mixins: [emitter],
        components: {
            SelectWrapper,
            SelectLabel,
            MyOrdersTable,
            SliderDialog,
            JobStatusDialog,
            ProjectCreateDialog,
            TransferJobDialog,
            AddItapDialog,
            RecommendDialog,
            JobPublishDialog,
            EditVisibleFirmDialog,
            JobShareEditDialog,
            ShareJobCardDialog,
            JobPersonalInfoDialog,
            MediaRecordEntry
        },
        props: {
            tableType: {
                type: Number,
                default() {
                    return 2;
                }
            },
        },
        data(){
            return{
                firstSearched: false,
                searchId: '',
                startDate: '',
                endDate: '',
                sortRule: 1,
                commissionType: 0,
                tableData: [],
                labelList: {
                    bubble: [],
                    titles: [],
                    customerNames: [],
                    status: [],
                    commissionType: [],
                    tags: []
                },
                orderLabelMap: new Set(),
                orderLabelShowList: [],
                headerFilter: {
                    titleSearchGroupedItems: [],
                    customerNameSearchGroupedItems: [],
                    statuSearchGroupedItems: [],
                    tagSearchGroupedItems: []
                },
                page: {
                    current: 1,
                    size: 20,
                    total:0
                },
                pageParams: {
                    start: 0,
                    take: 20,
                },
                currentAggregationType: 0,
                sliderDialog: {
                    status: 0,
                    job: {}
                },
                createdJobBubbleCount : {},
                grabbedJobBubbleCount : {},
                isFirstAddMyShareJob: false,
                jobShareIds: []
            }
        },
        computed: {
            searchParams() {
                let keyName = 'itemValue';
                const params = {
                    StartDate: this.startDate,
                    EndDate: this.endDate,
                    JobFilter: this.tableType,
                    BubbleFilter: this.labelList.bubble[0] ? this.labelList.bubble[0].code : 0,
                    OrderBy: this.sortRule == 1 ? (this.tableType == 1 ? 1 : 3) : 2,
                    CommissionType: this.commissionType,
                    JobsName: this.getLabelListParam('titles', 'text'),
                    CustomersName: this.getLabelListParam('customerNames', 'text'),
                    JobStatus: this.getLabelListParam('status', 'jobStatus'),
                    TagsName: this.getLabelListParam('tags', 'text'),
                    CurrentAggsType: this.currentAggregationType
                };
                return params;
            },
            tableTypeString() {
                return this.tableType == 1 ? 'MyOrdersCreatedJob' : 'MyOrdersGrabbedJob'
            }
        },
        watch: {
            searchParams() {
                this.$nextTick(() => {
                    this.search(false);
                })
            }
        },
        mounted() {
            this.page.total = this.tableData.length;
            this.$on('job-operation', item => {
                this.jobOperationDetail(item.command, item.data);
            });
            this.$on('update-item', item => {
                this.updateItem(item);
            });
            this.$on('refresh-search-tag',this.handleSearchTagRrefresh);
            this.myOrdersTable = this.$refs.myOrdersTable;
            if(window.localStorage) {
                let num = Number(localStorage.getItem('LBD_TABLE_PAGE_SIZE')) || 20;
                this.page.size = num;
                this.pageParams.take = num;
            }
            MyOrdersService.getTags()
                .then(res => {
                    this.$store.dispatch('setMyJobItaps', res);
                })
                .catch(err => {
                    console.log(err);
                    this.loading = false;
                });
            if(this.tableType == 1) {
                // this.checkMediaRecordingAuthority();
                if(this.$route.query.status) {
                    location.href = "/Headhunting/#/myOrders/createdJob";
                    this.$refs.selectWrapper.handleCommand({name:'全部',value:0}, null, true);
                    this.handleAddLabel('status', [{
                        isSelect: true,
                        jobStatus: 1,
                        label: "招聘中",
                        text: "招聘中"
                    }], true);
                }
            }
        },
        activated(){
            if(this.tableType === 1 && Object.keys(this.createdJobBubbleCount).length > 0) {
                this.$refs.selectWrapper.init(this.createdJobBubbleCount);
            } else if(Object.keys(this.grabbedJobBubbleCount).length > 0){
                this.$refs.selectWrapper.init(this.grabbedJobBubbleCount);
            }
        },
        methods: {
            showProjectCreateDialog() {
                this.$refs.projectCreateDialog.show();
            },
            refreshPorjectList() {
                this.$refs.jobStatusDialog.refreshPorjectList();
            },
            search(slient) {
                let params = this.searchParams;
                if (!slient) {
                    this.page.current = 1;
                    this.pageParams.start = 0;
                }
                Object.assign(params, this.pageParams);
                this.myOrdersTable.tableDataLoading = true;
                MyOrdersService.search(params).then((res) => {
                    this.page.total = res.myOrders.total;
                    this.setHeaderFilter('titleSearchGroupedItems', res.jobsNameInfo);
                    this.setHeaderFilter('customerNameSearchGroupedItems', res.customersInfo);
                    this.setHeaderFilter('statuSearchGroupedItems', res.jobsStatusInfo);
                    this.setHeaderFilter('tagSearchGroupedItems', res.tagsInfo);

                    res.myOrders.list.forEach((item, index) => {
                        item.index = index;
                        item.tags.sort((tag1, tag2) => {
                            let time1 = new Date(tag1.created).getTime(),
                                time2 = new Date(tag2.created).getTime();
                            if (time1 > time2) {
                                return -1
                            } else {
                                return 1
                            }
                        })
                    });

                    let keyName = 'text';
                    this.syncFilter(this.labelList.titles, this.headerFilter.titleSearchGroupedItems, keyName);
                    this.syncFilter(this.labelList.customerNames, this.headerFilter.customerNameSearchGroupedItems, keyName);
                    this.syncFilter(this.labelList.status, this.headerFilter.statuSearchGroupedItems, 'jobStatus');
                    this.syncFilter(this.labelList.tags, this.headerFilter.tagSearchGroupedItems, keyName);
                    this.tableData = res.myOrders.list;

                    this.myOrdersTable.tableDataLoading = false;

                    if (!slient && this.firstSearched) {
                        this.searchId = _generateUUID();
                        _tracker.track('MyOrdersSearch', JSON.stringify(Object.assign({}, {
                            searchId: this.searchId,
                            searchParams: Object.keys(params).map(item => {
                                switch (Object.prototype.toString.call(params[item])){
                                    case '[object Array]':
                                        if(params[item].length > 0) {
                                            return {
                                                field : item,
                                                value : params[item]
                                            }
                                        }
                                        break;
                                    case '[object Boolean]':
                                    case '[object String]':
                                        if(params[item]){
                                            return {
                                                field : item,
                                                value : params[item]
                                            }
                                        }
                                        break;
                                    case '[object Number]':
                                        return {
                                            field : item,
                                            value : params[item]
                                        };
                                }
                            }).filter(item => {
                                return item
                            }),
                            searchResult: {
                                matchCount: res.total,
                                firstPageJobIds: this.tableData.map(item => {
                                    return item.jobId
                                })
                            }
                        })))
                    } else if (slient){
                        _tracker.track('MyOrdersSearchResultPagingClick', JSON.stringify({
                            searchId: this.searchId,
                            pageIndex: this.page.current,
                            pageSize: this.pageParams.take
                            })
                        )
                    }

                    !this.firstSearched && (this.firstSearched = true);
                }).finally(() => {
                    this.myOrdersTable.scrollTop();
                    this.myOrdersTable.tableDataLoading = false;
                });
            },
            setHeaderFilter(headerFilterKeyName, orderArray) {
                if(!orderArray) {
                    return false;
                }
                const arr = this.headerFilter[headerFilterKeyName];
                while(arr.length > 0){
                    arr.pop();
                }
                if(orderArray.length > 0){
                    orderArray.forEach(item => {
                        arr.push(item);
                    });
                }
            },
            syncFilter(arr, goalArr, keyName) {
                if (arr.length > 0) {
                    arr.map(item => {
                        goalArr.map(param => {
                            if (item[keyName] == param[keyName]) {
                                param.isSelect = true;
                            }
                        })
                    })
                }
            },
            handleAddLabel(label, params, isCover) {
                const orderList = this.labelList[label];
                isCover = isCover || false;
                params = params.constructor === Object ? [params] : params;
                orderList.forEach(item => {
                    params.filter(param => {
                        return param.text !== item.text;
                    });
                });
                if (params.length <= 0) {
                    return false;
                }
                if(label == 'bubble') {
                    for (let key in this.labelList) {
                        this.labelList[key] = [];
                    }
                    this.commissionType = 0;
                    this.orderLabelMap.clear();
                }
                this.orderLabelMap.add(label);
                this.orderLabelShowList = Array.from(this.orderLabelMap);
                if (isCover) {
                    this.labelList[label] = params;
                } else {
                    this.labelList[label] = this.labelList[label].concat(params);
                }
                switch(label) {
                    case 'titles':
                        this.currentAggregationType = 1;
                        break;
                    case 'customerNames':
                        this.currentAggregationType = 2;
                        break;
                    case 'status':
                        this.currentAggregationType = 3;
                        break;
                    case 'tags':
                        this.currentAggregationType = 4;
                        break;
                    default:
                        this.currentAggregationType = 0;
                }
            },
            handleCleanLabel(label) {
                if (this.labelList[label].length <= 0) {
                    return false;
                }
                if(label == 'bubble') {
                    this.$refs.selectWrapper.clearResult();
                }
                if(label == 'commissionType') {
                    this.commissionType = 0;
                }
                this.orderLabelMap.delete(label);
                this.orderLabelShowList = Array.from(this.orderLabelMap);
                this.labelList[label] = [];
            },
            getLabelListParam(label, keyName) {
                const resultArr = [],
                      target = this.labelList[label];
                if(label == 'status') {
                    target.map(item => {
                        resultArr.push(Number(item[keyName]));
                    });
                } else {
                    target.map(item => {
                        resultArr.push(item[keyName]);
                    });
                }
                return resultArr;
            },
            handlePageChange(page) {
                if (page.type === 'current') {
                    this.page.current = page.current;
                    this.pageParams.start = (page.current - 1) * this.page.size;
                } else {
                    this.page.size = page.size;
                    this.page.current = 1;
                    this.pageParams.take = page.size;
                    this.pageParams.start = 0;
                }
                this.search(true);
            },
            getBubbleCount(){
                MyOrdersService
                    .getBubbleCount(this.startDate, this.endDate, this.tableType)
                    .then(res => {
                        if(this.tableType === 1){
                            this.createdJobBubbleCount = res;
                        } else {
                            this.grabbedJobBubbleCount = res;
                        }
                        this.$refs.selectWrapper.init(res);
                    })
            },
            timeLimitChange(val){
                this.startDate = val.startDate;
                this.endDate = val.endDate;
                this.getBubbleCount();
            },
            bubbleClick(val){
                this.handleAddLabel('bubble', val, true);
            },
            bubbleClear(){
                this.handleCleanLabel('bubble');
            },
            jobOperationDetail(command, dataJson) {
                let eventName = this.tableType == 1 ? 'MyOrdersCreatedJobOperation' : 'MyOrdersGrabbedJobOperation',
                    jobId = [];
                switch(Object.prototype.toString.call(dataJson)){
                    case '[object Object]':
                        jobId.push(dataJson.jobId);
                        break;
                    case '[object Array]':
                        dataJson.forEach(item => {
                            if(item){
                                jobId.push(item.jobId);
                            }
                        });
                        break;
                }
                switch(command) {
                    case 'changeJobStatus':
                        this.changeJobStatus(dataJson);
                        _tracker.track(eventName, JSON.stringify({
                            operationType: 'changeJobStatus',
                            operationText: '修改职位状态',
                        }));
                        break;
                    case 'addItap':
                        this.addItap(dataJson, false);
                        _tracker.track(eventName, JSON.stringify({
                            operationType: 'addItap',
                            operationText: '添加标签',
                        }));
                        break;
                    case 'recommend':
                        this.recommend(dataJson);
                        _tracker.track(eventName, JSON.stringify({
                            operationType: 'recommend',
                            operationText: '推荐候选人',
                        }));
                        break;
                    case 'changeRecommendation':
                        this.changeRecommendation(dataJson);
                        _tracker.track(eventName, JSON.stringify({
                            operationType: 'changeRecommendation',
                            operationText: '变更推荐状态',
                        }));
                        break;
                    case 'batchUpdateStatus':
                        this.batchUpdateJobStatus(dataJson);
                        _tracker.track(eventName, JSON.stringify({
                            operationType: 'batchUpdateStatus',
                            operationText: '批量修改职位状态',
                        }));
                        break;
                    case 'batchTransferJob':
                        this.batchTransferJob(dataJson);
                        _tracker.track(eventName, JSON.stringify({
                            operationType: 'batchTransferJob',
                            operationText: '批量移交职位',
                        }));
                        break;
                    case 'batchRecordJobVideo':
                        this.batchRecordJobVideo(dataJson);
                        _tracker.track(eventName, JSON.stringify({
                            operationType: 'batchRecordJobVideo',
                            operationText: '批量讲解职位-视频',
                        }));
                        break;
                    case 'batchRecordJobAudio':
                        this.batchRecordJobAudio(dataJson);
                        _tracker.track(eventName, JSON.stringify({
                            operationType: 'batchRecordJobAudio',
                            operationText: '批量讲解职位-语音',
                        }));
                        break;
                    case 'batchAddItap':
                        this.addItap(dataJson, true);
                        _tracker.track(eventName, JSON.stringify({
                            operationType: 'batchAddItap',
                            operationText: '批量添加标签'
                        }));
                        break;
                    case 'goProcess':
                        this.changeRecommendation(dataJson, 0);
                        _tracker.track(eventName, JSON.stringify({
                            operationType: 'goProcess',
                            operationText: '去处理'
                        }));
                        break;
                    case 'batchJobPublic':
                        this.jobPublic(dataJson);
                        _tracker.track(eventName, JSON.stringify({
                            operationType: 'batchJobPublic',
                            operationText: '批量发布到市场'
                        }));
                        break;
                    case 'batchEditPublicFirm':
                        this.editPublicFirm(dataJson);
                        _tracker.track(eventName, JSON.stringify({
                            operationType: 'batchEditPublicFirm',
                            operationText: '批量修改市场可见猎企'
                        }));
                        break;
                    case 'batchAddToShareCard':
                        this.addToShareCard(dataJson);
                        _tracker.track(eventName, JSON.stringify({
                            operationType: 'batchAddToShareCard',
                            operationText: '批量添加到职位卡片'
                        }));
                        break;
                }
            },
            changeJobStatus(jobData) {
                this.$refs.jobStatusDialog.initDialog({
                    type:'single',
                    checkedArr: [jobData],
                    jobStatusValue: jobData.jobStatus,
                    emergencyValue: jobData.emergencyDegree,
                    difficultyValue: jobData.difficultDegree,
                    jobId: jobData.jobId,
                    customerId: jobData.customerId,
                    customerDepartmentId: jobData.customerDepartmentId,
                })
            },
            batchUpdateJobStatus(checkedArr){
                this.$refs.jobStatusDialog.initDialog({
                    type:'batch',
                    checkedArr: checkedArr,
                    jobStatusValue: -1,
                    emergencyValue: -1,
                    difficultyValue: -1
                })
                //状态为-1表示不变
            },
            batchTransferJob(checkedArr){
                this.$refs.transferJobDialog.initDialog({
                    checkedArr: checkedArr
                })
            },
            batchRecordJobVideo(checkedArr) {
                let jobIds = [];
                checkedArr.forEach(item => {
                    jobIds.push(item.jobId)
                })
                console.log(jobIds);
                this.$refs.mediaRecordEntry.show('video', jobIds);
            },
            batchRecordJobAudio(checkedArr) {
                let jobIds = [];
                checkedArr.forEach(item => {
                    jobIds.push(item.jobId)
                })
                console.log(jobIds);
                this.$refs.mediaRecordEntry.show('audio', jobIds);
            },
            addItap(jobData, isBatch) {
                if(isBatch) {
                    this.$refs.addItapDialog.list = jobData;
                } else {
                    this.$refs.addItapDialog.list = [];
                    this.$refs.addItapDialog.list.push(jobData);
                }
                this.$refs.addItapDialog.show(isBatch);
            },
            recommend(jobData) {
                this.$refs.recommendDialog.show(jobData.jobId, jobData.jobName,jobData.jobType, jobData.jobType);
            },
            changeRecommendation(jobData, status) {
                this.sliderDialog.status = status === 0 ? status : 'all';
                this.sliderDialog.job = jobData;
                this.$nextTick(() => {
                    this.$refs.sliderDialog.handleShow();
                })
            },
            handleSortRule(value) {
                this.sortRule = value;
            },
            handleCommissionValueType(type) {
                this.commissionType = type;
            },
            updateItem(job){
                let index = job.index;
                let jobId = job.jobId;
                let type = this.$route.path == '/myOrders/createdJob'?1:2;
                MyOrdersService
                    .updateSingle(jobId,type)
                    .then(res => {
                        let jobItem = res;
                        jobItem.index = index;
                        jobItem.tags.sort((tag1, tag2) => {
                            let time1 = new Date(tag1.created).getTime(),
                                time2 = new Date(tag2.created).getTime();
                            if (time1 > time2) {
                                return -1
                            } else {
                                return 1
                            }
                        })
                        this.$set(this.tableData,index,jobItem);
                    })
                    .catch(err => {
                        console.log(err);
                    });
            },
            handleSearchTagRrefresh(itap){
                const param = this.headerFilter.tagSearchGroupedItems.find((item) => {
                    return item.text === itap;
                });
                if(param) {
                    param.count = param.count + 1
                } else {
                    this.headerFilter.tagSearchGroupedItems.push({
                        itemText: itap,
                        itemValue: itap,
                        isSelect: false,
                        count: 1,
                        text: itap,
                        label: itap
                    })
                }
            },
            jobPublic(dataJson) {
                this.$refs.jobPublishDialog.show(dataJson, false);
            },
            editPublicFirm(dataJson) {
                this.$refs.editVisibleFirmDialog.show(dataJson);
            },
            addToShareCard(dataJson) {
                let ids = [];
                dataJson.forEach(job => {
                    ids.push(job.jobId)
                })
                this.jobShareIds = ids;
                this.$refs.jobShareEditDialog.show(false, {
                    jobIdList: this.jobShareIds
                });
            },
            showShareJobCardDialog() {
                this.$refs.shareJobCardDialog.show();
            },
            showJobPersonalInfoDialog() {
                this.isFirstAddMyShareJob = true;
                this.$nextTick(() => {
                    this.$refs.jobPersonalInfoDialog.show(true);
                })
            },
            updatePersonalInfo() {
                // 编辑完个人信息，重新展示职位编辑弹窗
                this.$refs.jobShareEditDialog.show(false, {
                    jobIdList: this.jobShareIds
                });
            },
            checkMediaRecordingAuthority() {
                MediaService.checkRecordingAuthority({
                    jobId: ""
                }).then(res => {
                    this.$set(this.$refs.myOrdersTable, 'canRecord', res.openStatus);
                    if(res.openStatus && this.tableType == 1) {
                        let today = new Date().getTime();
                        if((sessionStorage.getItem('multipleCreateJob')) || (today >= new Date('2020/06/17 00:00:00').getTime() && today < new Date('2020/06/20 00:00:00') && (!localStorage.getItem('MulRecordGuideDate') || localStorage.getItem('MulRecordGuideDate') !== moment(today).format('YYYYMMDD')))) {
                            document.querySelector(".work-table-footer").style.overflow = 'unset';
                            this.$set(this.$refs.myOrdersTable.$refs.batchOperation, 'isShowRecordGuide', true);
                            localStorage.setItem('MulRecordGuideDate', moment(today).format('YYYYMMDD'));
                            if(sessionStorage.getItem('multipleCreateJob')) {
                                sessionStorage.removeItem('multipleCreateJob');
                            }
                        }
                    }
                }).catch(err => {
                    console.log(err)
                })
            }
        }
    }
</script>

<style lang="scss" scope>
.work-table-container {
    height: 100%;
    overflow-y: auto;
    .my-orders-wrapper {
        position: relative;
        height: 100%;
        min-height: 500px;
        display: flex;
        flex-direction: column;
        overflow: hidden;
    }
}
</style>
