var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading",
        },
      ],
      attrs: {
        width: "480px",
        title: "修改市场可见猎企",
        visible: _vm.dialogVisible,
        "close-on-click-modal": false,
        "close-on-press-escape": false,
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogVisible = $event
        },
        close: _vm.handleCloseDialog,
      },
    },
    [
      _c("span", { staticClass: "el-form-item__label" }, [
        _vm._v("设置可见猎企:"),
      ]),
      _c(
        "el-select",
        {
          staticClass: "input-visible-firm",
          attrs: { multiple: "", placeholder: "请选择可见猎企" },
          model: {
            value: _vm.visibleFirmIdList,
            callback: function ($$v) {
              _vm.visibleFirmIdList = $$v
            },
            expression: "visibleFirmIdList",
          },
        },
        [
          _c("el-option", {
            attrs: { label: "全部猎企(默认)", value: 0 },
            nativeOn: {
              click: function ($event) {
                $event.preventDefault()
                return _vm.firmSelectedAll.apply(null, arguments)
              },
            },
          }),
          _vm._l(_vm.companyList, function (item, index) {
            return _c("el-option", {
              key: index,
              staticClass: "company-item",
              attrs: { label: item.name, value: item.firmId },
              nativeOn: {
                click: function ($event) {
                  return _vm.firmSelected(index)
                },
              },
            })
          }),
          _vm.hasMoreCompany
            ? _c("el-option", {
                staticClass: "get-more-btn",
                attrs: { label: "加载更多" },
                nativeOn: {
                  click: function ($event) {
                    $event.stopPropagation()
                    return _vm.getCompanyList.apply(null, arguments)
                  },
                },
              })
            : _vm._e(),
          _c("span", { attrs: { slot: "empty" }, slot: "empty" }, [
            _vm._v("暂无数据"),
          ]),
        ],
        2
      ),
      _c(
        "span",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c("el-button", { on: { click: _vm.handleCloseDialog } }, [
            _vm._v("取消"),
          ]),
          _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.editVisibleFirm } },
            [_vm._v("确定")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }