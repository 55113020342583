var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "batch-operation" },
    [
      _vm.isMyOrder
        ? _c(
            "el-button",
            {
              staticClass: "batch-operation-btn update-status",
              attrs: { disabled: _vm.selectList.length == 0 },
              on: {
                click: function ($event) {
                  return _vm.batchOperate("batchUpdateStatus")
                },
              },
            },
            [_vm._v("\n        修改职位状态\n    ")]
          )
        : _vm._e(),
      _c(
        "el-button",
        {
          staticClass: "batch-operation-btn add-itap",
          attrs: { disabled: _vm.selectList.length == 0 },
          on: {
            click: function ($event) {
              return _vm.batchOperate("batchAddItap")
            },
          },
        },
        [_vm._v("\n        打标签\n    ")]
      ),
      _vm.isMyOrder && _vm.canRecord
        ? _c(
            "el-dropdown",
            {
              staticClass: "record-job-dropdown",
              style: _vm.isShowRecordGuide ? "z-index: 4001;" : "",
              attrs: { placement: "top-start" },
              on: { command: _vm.handleBatchRecordJob },
            },
            [
              _c(
                "el-button",
                {
                  staticClass: "batch-operation-btn record-job",
                  style: _vm.isShowRecordGuide ? "pointer-events: none;" : "",
                  attrs: { disabled: _vm.selectList.length == 0 },
                },
                [_vm._v("\n            职播\n        ")]
              ),
              _vm.isMyOrder && _vm.canRecord && _vm.isShowRecordGuide
                ? _c("div", { staticClass: "multiple-record-guide" }, [
                    _c(
                      "span",
                      {
                        staticClass: "text-operate-btn",
                        on: { click: _vm.hideMulRecordGuide },
                      },
                      [_vm._v("好的")]
                    ),
                  ])
                : _vm._e(),
              _c(
                "el-dropdown-menu",
                { attrs: { slot: "dropdown" }, slot: "dropdown" },
                [
                  _c("el-dropdown-item", { attrs: { command: "video" } }, [
                    _vm._v("视频讲解"),
                  ]),
                  _c("el-dropdown-item", { attrs: { command: "audio" } }, [
                    _vm._v("语音讲解"),
                  ]),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      !_vm.isMyOrder ||
      !(
        _vm.isMyOrder &&
        (_vm.user.canPublicizeInternalJob || _vm.user.isAdministrator) &&
        _vm.user.isMarketJobWhiteList
      )
        ? _c(
            "el-button",
            {
              staticClass: "batch-operation-btn add-share-job",
              attrs: { disabled: _vm.selectList.length == 0 },
              on: {
                click: function ($event) {
                  return _vm.batchOperate("batchAddToShareCard")
                },
              },
            },
            [_vm._v("\n        分享职位\n    ")]
          )
        : _vm._e(),
      _vm.isMyOrder
        ? _c(
            "el-dropdown",
            {
              staticClass: "more-operation-dropdown",
              attrs: { placement: "top-start" },
            },
            [
              _c(
                "el-button",
                {
                  staticClass: "batch-operation-btn more-operation",
                  attrs: {
                    disabled: _vm.selectList.length == 0,
                    icon: "el-icon-menu",
                  },
                },
                [_vm._v("\n            更多操作\n        ")]
              ),
              _c(
                "el-dropdown-menu",
                {
                  staticClass: "more-operation-menu",
                  attrs: { slot: "dropdown" },
                  slot: "dropdown",
                },
                [
                  _c(
                    "el-dropdown-item",
                    {
                      attrs: { disabled: _vm.selectList.length == 0 },
                      nativeOn: {
                        click: function ($event) {
                          return _vm.batchOperate("batchTransferJob")
                        },
                      },
                    },
                    [_vm._v("\n                移交职位\n            ")]
                  ),
                  (_vm.user.canPublicizeInternalJob ||
                    _vm.user.isAdministrator) &&
                  _vm.user.isMarketJobWhiteList
                    ? _c(
                        "el-dropdown-item",
                        {
                          attrs: { disabled: _vm.cannotPublic },
                          nativeOn: {
                            click: function ($event) {
                              return _vm.batchOperate("batchJobPublic")
                            },
                          },
                        },
                        [_vm._v("\n                发布到市场\n            ")]
                      )
                    : _vm._e(),
                  (_vm.user.canPublicizeInternalJob ||
                    _vm.user.isAdministrator) &&
                  _vm.user.isMarketJobWhiteList
                    ? _c(
                        "el-dropdown-item",
                        {
                          attrs: { disabled: _vm.cannotEditPublicFirm },
                          nativeOn: {
                            click: function ($event) {
                              return _vm.batchOperate("batchEditPublicFirm")
                            },
                          },
                        },
                        [
                          _vm._v(
                            "\n                修改市场可见猎企\n            "
                          ),
                        ]
                      )
                    : _vm._e(),
                  (_vm.user.canPublicizeInternalJob ||
                    _vm.user.isAdministrator) &&
                  _vm.user.isMarketJobWhiteList
                    ? _c(
                        "el-dropdown-item",
                        {
                          nativeOn: {
                            click: function ($event) {
                              return _vm.batchOperate("batchAddToShareCard")
                            },
                          },
                        },
                        [
                          _vm._v(
                            "\n                添加到职位卡片\n            "
                          ),
                        ]
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _vm.isShowRecordGuide
        ? _c("div", { staticClass: "job-record-guide-modal" })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }